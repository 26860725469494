let BRI_PLATFORMS = {
  insurace:{
    name: 'InsurAce',
    nameId: 'insurace',
    imgSrc: require('../assets/img/insurace_icon.png'),
    exposure: null,
    token: ['usdc,', 'usdt'],
    assets: [],
    fetchedProtocols: 0,
    // worth per 'risk protocol', denominated in 'base'
    protocolWorth: 0,
    controllerAddress: [],
  },
  bridge:{
    name: 'Bridge Mutual',
    nameId: 'bridge',
    imgSrc: require('../assets/img/bridge_mutual.png'),
    exposure: null,
    token: ['usdt'],
    assets: [],
    fetchedProtocols: 0,
    // worth per 'risk protocol', denominated in 'base'
    protocolWorth: 0,
    controllerAddress: [],
  },
  nexus:{
    name: 'Nexus Mutual',
    nameId: 'nexus',
    imgSrc: require('../assets/img/nexus_mutual.png'),
    exposure: null,
    token: ['nxm'],
    assets: [],
    fetchedProtocols: 0,
    // worth per 'risk protocol', denominated in 'base'
    protocolWorth: 0,
    controllerAddress: [],
  },
}


const indexAddress_mainnet = '0xa4b032895BcB6B11ec7d21380f557919D448FD04';
const indexAddress_rinkeby = '0xA1bCE8b49891c2521Fa1c06Ef7f63bea3B947Da3';
const indexAddress_kovan = '0xa879eDb0751dD2DeC96BC26F90B5114A2Ac299Bd';

const nexusStakingViewer = '0xcafea2b7904ee0089206ab7084bcafb8d476bd04';

function getIndexAddress(_netId) {
  if(_netId == 1){
    return indexAddress_mainnet;
  }else if (_netId == 42) {
    return indexAddress_kovan;
  }else if (_netId == 4) {
    return indexAddress_rinkeby;
  }
}

module.exports = {
    BRI_PLATFORMS,
    nexusStakingViewer,
    indexAddress_kovan,
    indexAddress_rinkeby,
    indexAddress_mainnet,
    getIndexAddress
}
