import {
  fromWei as fromWeiImport ,
  toBN as toBNImport,
  toWei as toWeiImport,
} from 'web3-utils';

export const toWei = (value) => {
  return toWeiImport(value.toString(), 'ether');
};

export const toBN = (val) =>  {
  if(typeof val == "number"){
    val = val.toString();
  }
  val = val.includes('.') ? val.split(".")[0] : val;
  return toBNImport(val);
};

export const fromWei = (value , type) => {
  if(typeof value == "undefined"){
    value = '0';
  }
  if(typeof value == "number"){
    value = value.toString();
  }
  if(typeof value == "string"){
    value = value.includes('.') ? value.split(".")[0] : value;
  }
  return fromWeiImport(value.toString(), type ? type : "ether" );
}

export const flexDecimals = (value) => {

  const formateNum = Number(value);

  let checkDecimal = 1;
  const relevantDigits = (num) => {
    while (checkDecimal * Math.abs(num) < 1) {
      checkDecimal = 10 * checkDecimal;
      if(checkDecimal * Math.abs(num) >= 1){
        return num.toFixed(checkDecimal.toString().length);
      }
    }
  }

  if(formateNum === 0){
    return formateNum;
  }else if (formateNum >= 1000 || formateNum <= -1000) {
    return Number(formateNum).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }else if (formateNum >= 100 || formateNum <= -100) {
    return formateNum.toFixed(0); // 0 decimals
  }else if (formateNum >= 1 || formateNum <= -1) {
    return formateNum.toFixed(2); // 2 decimals
  }else if (formateNum < 1 && formateNum > -1) {
   return relevantDigits(formateNum); // always 2 relevant digits after zeros
 }

}

export const formatCapacity = (num) => {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    {v: 1E3, s: "k"},
    {v: 1E6, s: "M"},
    {v: 1E9, s: "b"}
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
}

export const usd = (value) => {
  return Number(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}
export const numberFourDecimals = (value) => {
  return Number((Math.floor(Number(value) * 10000) / 10000).toFixed(4)).toLocaleString(undefined, { minimumFractionDigits: 4 });
}
export const numberTwoDecimals = (value) => {
  return Number((Math.floor(Number(value) * 100) / 100).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2 });
}

export const numberOneDecimal = (value) => {
  return Number((Math.floor(Number(value) * 10) / 10).toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1 });
}
