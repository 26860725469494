// import code-splitting and lazy-loading of contracts
// this generates a separate chunk (webpackChunkName.[hash].js) for these imports (/* webpackChunkName: "nexuscontracts" */ )
// which is lazy-loaded when the contract is loaded.
const CONTRACTS = {
  BridgePolicyBookABI:  import(/* webpackChunkName: "bridgecontracts" */ '../assets/abi/bridge/IPolicyBook.json' ),
  InsuraceStakersPoolABI:import(/* webpackChunkName: "insuracecontracts" */ '../assets/abi/insurace/IStakersPool.json'),
  BrightRiskTokenABI: import(/* webpackChunkName: "brightcontracts" */ '../assets/abi/BrightRiskToken.json'),
  NexusControllerABI: import(/* webpackChunkName: "nexuscontracts" */  '../assets/abi/NexusPositionController.json'),
  InsuraceControllerABI:  import(/* webpackChunkName: "insuracecontracts" */ '../assets/abi/InsuracePositionController.json' ),
  BridgeControllerABI: import(/* webpackChunkName: "bridgecontracts" */ '../assets/abi/BridgeLeveragedPositionController.json'),
  IUserLeveragePoolABI: import(/* webpackChunkName: "brightcontracts" */ '../assets/abi/bridge/IUserLeveragePool.json'),
  AbstractControllerABI: import(/* webpackChunkName: "brightcontracts" */ '../assets/abi/AbstractController.json'),
  PriceFeedContractABI: import(/* webpackChunkName: "brightcontracts" */ '../assets/abi/PriceFeed.json'),
  BMICoverStaking: import(/* webpackChunkName: "brightcontracts" */ '../assets/abi/bridge/BMICoverStaking.json'),
  NexusDistributorABI:import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/Distributor.json'),
  NexusMasterABI:import(/* webpackChunkName: "nexuscontracts" */  '../assets/abi/nexus/INXMaster.json' ),
  NexusStakingABI: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/IPooledStaking.json'),
  NexusGatewayABI: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/IGateway.json'),
  StakingViewer: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/StakingViewer.json'),
  StakingNFTDescriptor: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/StakingNFTDescriptor.json'),
  StakingPool: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/StakingPool.json'),
  NexusPoolABI: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/IPool.json'),
  IERC20ABI: import(/* webpackChunkName: "erccontracts" */ '../assets/abi/IERC20Metadata.json'),
  ERC20PermitABI: import(/* webpackChunkName: "erccontracts" */ '../assets/abi/ERC20Permit.json'),
  UniPairABI: import(/* webpackChunkName: "unicontracts" */ '../assets/abi/IUniswapV2Pair.json'),
  ContractsRegistryABI: import(/* webpackChunkName: "contracts" */ '../assets/abi/ContractsRegistry.json'),
  BrightStakingABI: import(/* webpackChunkName: "brightcontracts" */ '../assets/abi/BrightStaking'),
  LiquidityMiningStakingABI: import(/* webpackChunkName: "brightcontracts" */ '../assets/abi/LiquidityMiningStaking'),
  QuotationABI: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/Quotation'),
  TokenControllerABI: import(/* webpackChunkName: "nexuscontracts" */ '../assets/abi/nexus/ITokenController'),
}

const getContractByAsyncChunk = (abiName, address, web3) =>  new Promise((resolve, reject) => {
  CONTRACTS[abiName].then((module) => {
    const contractInstance = new web3.eth.Contract(module.abi, address);
    if (contractInstance) {
      resolve(contractInstance);
    } else {
      reject('Can\'t get contact');
    }
  });
});

export const getNexusDistributorContract = (address, web3) => getContractByAsyncChunk("NexusDistributorABI", address, web3);
export const getNexusMasterContract = (address, web3) => getContractByAsyncChunk("NexusMasterABI", address, web3);
export const getNexusStakingContract = (address, web3) => getContractByAsyncChunk("NexusStakingABI", address, web3);
export const getNexusGatewayContract = (address, web3) => getContractByAsyncChunk("NexusGatewayABI", address, web3);
export const getNexusPool = (address, web3) => getContractByAsyncChunk("NexusPoolABI", address, web3);
export const getNexusStakingViewer = (address, web3) => getContractByAsyncChunk("StakingViewer", address, web3);
export const getNexusStakingNFTDescriptor = (address, web3) => getContractByAsyncChunk("StakingNFTDescriptor", address, web3);
export const getNexusStakingPool = (address, web3) => getContractByAsyncChunk("StakingPool", address, web3);
export const getIERC20Contract = (address, web3) => getContractByAsyncChunk("IERC20ABI", address, web3);
export const getERC20PermitContract = (address, web3) => getContractByAsyncChunk("ERC20PermitABI", address, web3);
export const getUniPairContract = (address, web3) => getContractByAsyncChunk("UniPairABI", address, web3);
export const getContractsRegistryContract = (address, web3) => getContractByAsyncChunk("ContractsRegistryABI", address, web3);
export const getBrightStakingContract = (address, web3) => getContractByAsyncChunk("BrightStakingABI", address, web3);
export const getLiquidityMiningStakingContract = (address, web3) => getContractByAsyncChunk("LiquidityMiningStakingABI", address, web3);
export const getBridgePolicyBookContract = (address, web3) => getContractByAsyncChunk("BridgePolicyBookABI", address, web3);
export const getInsurAceStakersPoolContract = (address, web3) => getContractByAsyncChunk("InsuraceStakersPoolABI", address, web3);
export const getBrightRiskTokenContract = (address, web3) => getContractByAsyncChunk("BrightRiskTokenABI", address, web3);
export const getNexusControllerContract = (address, web3) => getContractByAsyncChunk("NexusControllerABI", address, web3);
export const getInsuraceControllerContract = (address, web3) => getContractByAsyncChunk("InsuraceControllerABI", address, web3);
export const getBridgeControllerContract = (address, web3) => getContractByAsyncChunk("BridgeControllerABI", address, web3);
export const getBridgeCoverStakingContract = (address, web3) => getContractByAsyncChunk("BMICoverStaking", address, web3);
export const getBridgeLeveragedPortfolioContract = (address, web3) => getContractByAsyncChunk("IUserLeveragePoolABI", address, web3);
export const getAbstractControllerContract = (address, web3) => getContractByAsyncChunk("AbstractControllerABI", address, web3);
export const getPriceFeedContract = (address, web3) => getContractByAsyncChunk("PriceFeedContractABI", address, web3);
export const getNexusQuotationContract = (address, web3) => getContractByAsyncChunk("QuotationABI", address, web3);
export const getNexusTokenControllerContract = (address, web3) => getContractByAsyncChunk("TokenControllerABI", address, web3);
