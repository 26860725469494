
import { fromWei } from '@/utils/filters.js';
import {
  getBrightStakingContract,
  getIERC20Contract,
  getLiquidityMiningStakingContract
} from '@/utils/getContract'
import moment from "moment";
import EventBus from '@/utils/event-bus'


const moduleStakeBright = {
  state: () => ({
    tvl:  null,
    apy: 0,
    lmAPY: 0,

    myBalance:  0,
    myUniBalance:  0,
    myStkBalance:  0,
    supplyingBright:  0,
    lmRewards:  0,
    lmMyStake:  0,

    withdrawInfo: {},
    tvlBright: null,
    lmStaked: 0,
    withdrawBefore: '',
    withdrawAvailableFor: '',
    canWithdraw: false,
    withdrawableAmount: 0,
    withdrawAvalableIn: '',

    lmRewardsPerSecInWei:0,
    lmWithdrawInfo: {},
    lmWithdrawAvalableAtMoment: '',
    lmWithdrawAvalableIn: '',
    lmCanWithdraw: false,
    lmWithdrawAvailableFor: '',
    lmWithdrawBefore: 0,
    lmRewardsInterval: null,
  }),
  mutations: {
    resetBalanceData(state){
      state.tvl = null;
      state.myBalance = 0;
      state.myUniBalance = 0;
      state.lmAPY = 0;
      state.apy = 0;
      state.myStkBalance = 0;
      state.supplyingBright = 0;
      state.lmRewards = 0;
      state.lmMyStake = 0;
    },
    setTVL(state, payload){
      state.tvl = payload;
    },
    setAPY(state, payload){
      state.apy = payload;
    },
    setLMAPY(state, payload){
      state.lmAPY = payload;
    },
    setMyBalance(state, payload){
      state.myBalance = payload;
    },
    setMyUniBalance(state, payload){
      state.myUniBalance = payload;
    },
    setMyStkBalance(state, payload){
      state.myStkBalance = payload;
    },
    setSupplyingBright(state, payload){
      state.supplyingBright = payload;
    },
    setLMRewards(state, payload){
      state.lmRewards = payload;
    },
    setLMMyStake(state, payload){
      state.lmMyStake = payload;
    },
  },
  actions: {

    loadBrightStaking ({state, rootState, commit, getters, dispatch} ) {
      rootState.contractsRegistry().methods.getBrightStakingContract().call().then(stakingAddress => {
        getBrightStakingContract(stakingAddress, getters.xWeb3Eth).then(stakingInstance => {
          stakingInstance.methods.getStakedBright(getters.myAddress).call().then(mySupply => {
            commit("setSupplyingBright" ,  fromWei(mySupply) );
          });
          stakingInstance.methods.totalPool().call().then(totalBrightPool => {
            state.tvlBright = Number(fromWei(totalBrightPool));

            stakingInstance.methods.rewardPerBlock().call().then(rewardPerBlock => {

              const apy = (((Number(fromWei(rewardPerBlock)) * 4 * 60 * 24 * 365) / state.tvlBright) * 100).toFixed(1);
              commit("setAPY" , apy );

            rootState.contractsRegistry().methods.getSTKBrightContract().call().then(stkBrightAddress => {
                getIERC20Contract(stkBrightAddress, getters.xWeb3Eth).then(stkBright => {
                  stkBright.methods.balanceOf(getters.myAddress).call().then(stkBalance => {
                    commit("setMyStkBalance" , Number(fromWei(stkBalance)) )
                  })
                })
              });
            })

            dispatch("getWithdrawInfo" , { stakingInstance: stakingInstance });
            dispatch("loadUniBalance" );

          })

        });
        rootState.brightToken().methods.balanceOf(getters.myAddress).call().then(brightBalance => {
          commit("setMyBalance" , Number(fromWei(brightBalance)) )
        })
      });
    },
    getWithdrawInfo( { state, getters, dispatch } , { stakingInstance } ) {
      stakingInstance.methods.getWithdrawalInfo(getters.myAddress).call().then(withdrawInfo => {
        state.withdrawInfo = withdrawInfo;
        stakingInstance.methods.stakingReward(state.withdrawInfo._amount).call().then(withdrawable => {
          state.withdrawableAmount = fromWei(withdrawable);
        })
        dispatch("setStakingTimer");
      });
    },
    loadUniBalance ({rootState, commit, dispatch, getters}) {
      return rootState.uniPairToken().methods.balanceOf(getters.myAddress).call().then(uniBalance => {
        commit("setMyUniBalance" , Number(fromWei(uniBalance)) );
        dispatch("loadTVL");
      })
    },
    loadTVL ({rootState, getters, commit, state}) {
      rootState.uniPairToken().methods.getReserves().call().then(reserves => {
        rootState.uniPairToken().methods.totalSupply().call().then(supply => {
          const percentStaked = state.lmStaked * 100 / Number(fromWei(supply));

          const uniswapLiquidityETH = Number(fromWei(reserves.reserve1)) + Number(getters.bright2eth(fromWei(reserves.reserve0)));
          const uniswapLiquidityUSD = getters.eth2usd(uniswapLiquidityETH);

          const stakedBrightUSD = getters.eth2usd(getters.bright2eth(Number(state.tvlBright)))

          const tvl = (Number(uniswapLiquidityUSD) * percentStaked / 100) + Number(stakedBrightUSD);
          commit("setTVL" , tvl);
        })
      });
    },
    setStakingTimer ({state} ) {
      if (state.withdrawInfo._unlockPeriod && state.withdrawInfo._unlockPeriod !== '0') {
        const unlockMoment = moment(state.withdrawInfo._unlockPeriod * 1000);
        state.withdrawAvalableAtMoment = unlockMoment.format('MMMM Do, HH:mm');
        state.withdrawAvalableIn = unlockMoment.fromNow();
      }

      if (state.withdrawInfo._availableFor && state.withdrawInfo._availableFor !== '0') {
        const endUnlockMoment = moment(state.withdrawInfo._availableFor * 1000);
        state.withdrawBefore = endUnlockMoment.format('MMMM Do, HH:mm');
        state.withdrawAvailableFor = endUnlockMoment.fromNow();
        state.canWithdraw = true;
      }
    },
    loadLMStaking ({state, commit, rootState, dispatch, getters }) {
      rootState.contractsRegistry().methods.getLiquidityMiningStakingContract().call().then(liquidityMiningStakingAddress => {
        getLiquidityMiningStakingContract(liquidityMiningStakingAddress, getters.xWeb3Eth).then(liquidityMiningStaking => {
          liquidityMiningStaking.methods.totalStaked().call().then(staked => {
            state.lmStaked = Number(fromWei(staked));
            liquidityMiningStaking.methods.staked(getters.myAddress).call().then(myStake => {

              commit("setLMMyStake" , Number(fromWei(myStake)) );

              if (state.lmMyStake > 0) {
                liquidityMiningStaking.methods.rewardPerBlock().call().then(rewardsPerBlock => {
                  const myPortion = state.lmMyStake / state.lmStaked;
                  state.lmRewardsPerSecInWei = (myPortion * rewardsPerBlock) / 13;
                  if (state.lmRewardsInterval) {
                    clearInterval(state.lmRewardsInterval);
                  }
                  state.lmRewardsInterval = setInterval(() => {
                    let lmRewards = (Number(state.lmRewards) + parseFloat(fromWei(state.lmRewardsPerSecInWei.toFixed(0).toString()))).toFixed(4);
                    commit("setLMRewards" , lmRewards);
                  }, 1000);
                })
              }
            })
          })

          liquidityMiningStaking.methods.getAPY().call().then(apy => {
            const lmAPY = Number(apy) / Math.pow(10, 5);
            commit("setLMAPY" , lmAPY );
          })
          liquidityMiningStaking.methods.earned(getters.myAddress).call().then(myRewards => {
            commit("setLMRewards" , fromWei(myRewards));

          })
          dispatch("getLMWithdrawInfo" , {stakingInstance:liquidityMiningStaking})
        })
      });
    },
    getLMWithdrawInfo ({state, dispatch, getters},{stakingInstance}) {
      stakingInstance.methods.getWithdrawalInfo(getters.myAddress).call().then(withdrawInfo => {
        state.lmWithdrawInfo = withdrawInfo;
        dispatch("setLMTimer" )

      });
    },
    setLMTimer ( { state } ) {
      if (state.lmWithdrawInfo._unlockPeriod && state.lmWithdrawInfo._unlockPeriod !== '0') {
        const unlockMoment = moment(state.lmWithdrawInfo._unlockPeriod * 1000);
        state.lmWithdrawAvalableAtMoment = unlockMoment.format('MMMM Do, HH:mm');
        state.lmWithdrawAvalableIn = unlockMoment.fromNow();
      }

      if (state.lmWithdrawInfo._availableFor && state.lmWithdrawInfo._availableFor !== '0') {
        const endUnlockMoment = moment(state.lmWithdrawInfo._availableFor * 1000);
        state.lmWithdrawBefore = endUnlockMoment.format('MMMM Do, HH:mm');
        state.lmWithdrawAvailableFor = endUnlockMoment.fromNow();
        state.lmCanWithdraw = true;
      }
    },
    brightStakedWithdraw ({rootState, getters}) {
      rootState.contractsRegistry().methods.getBrightStakingContract().call().then(stakingAddress => {
        getBrightStakingContract(stakingAddress, getters.xWeb3Eth).then(stakingInstance => {
          stakingInstance.methods.withdraw().send({from: getters.myAddress})
              .on('transactionHash', (transactionHash) => {
                EventBus.publish('SHOW_CONFIRMATION_DONE', {msg: 'Thanks for using us!', tx: transactionHash});
                EventBus.publish('TX_CONFIRMING');
                EventBus.publish('TRACK_EVENT', {
                  action: 'withdraw-transaction-hash',
                  category: 'trxHash',
                  label: 'Transaction Hash',
                  value: 1
                });
              })
              .on('confirmation', (confirmationNumber) => {
                if (confirmationNumber === 0) {
                  EventBus.publish('TX_CONFIRMED');
                  EventBus.publish('BRIGHT_STAKING_UPDATED');
                  EventBus.publish('TRACK_EVENT', {
                    action: 'withdraw-transaction-confirmed',
                    category: 'trxConfirmation',
                    label: 'Transaction Confirmed',
                    value: 1
                  });
                }
              })
              .on('error', (msg) => {
                console.error(msg);
                EventBus.publish('CLOSE_CONFIRMATION_WAITING');
                // this.showModal = true;
                EventBus.publish('TRACK_EVENT', {
                  action: 'withdraw-transaction-error',
                  category: 'trxError',
                  label: 'Transaction Failed',
                  value: 1
                });
              });
        })
      })
    },
    brightLMStakedWithdraw ({ rootState, getters}) {
      rootState.contractsRegistry().methods.getLiquidityMiningStakingContract().call().then(liquidityMiningStakingAddress => {
        getLiquidityMiningStakingContract(liquidityMiningStakingAddress, getters.xWeb3Eth).then(liquidityMiningStaking => {
          liquidityMiningStaking.methods.getReward().send({from: getters.myAddress})
              .on('transactionHash', (transactionHash) => {
                EventBus.publish('SHOW_CONFIRMATION_DONE', {msg: 'Thanks for using us!', tx: transactionHash});
                EventBus.publish('TX_CONFIRMING');
                EventBus.publish('TRACK_EVENT', {
                  action: 'lm-withdraw-transaction-hash',
                  category: 'trxHash',
                  label: 'Transaction Hash',
                  value: 1
                });
                this.$refs.lmWithdrawButton.track('lm-withdraw-transaction-hash', 'trxHash', 'Transaction Hash', 1);
              })
              .on('confirmation', (confirmationNumber) => {
                if (confirmationNumber === 0) {
                  EventBus.publish('TX_CONFIRMED');
                  EventBus.publish('LM_STAKING_UPDATED');
                  EventBus.publish('TRACK_EVENT', {
                    action: 'lm-withdraw-transaction-confirmed',
                    category: 'trxConfirmation',
                    label: 'Transaction Confirmed',
                    value: 1
                  });
                }
              })
              .on('error', (msg) => {
                console.error(msg);
                EventBus.publish('CLOSE_CONFIRMATION_WAITING');
                this.showModal = true;
                EventBus.publish('TRACK_EVENT', {
                  action: 'lm-withdraw-transaction-error',
                  category: 'trxError',
                  label: 'Transaction Failed',
                  value: 1
                });
              });
        })
      })
    },


  }, // actions
  getters: {

  }

};

export {moduleStakeBright}
