//local development for SDK******************************
//import BrightClient from '@/../node_modules/brightunion_dev/index.js';
//local development for SDK******************************

import BrightClient from '@brightunion/sdk';
import EventBus from "@/utils/event-bus";
import moment from 'moment';

const moduleCovers = {
  state: () => ({
    brightClient : null, //BrightClient SDK
    brightClientActive : false,

    allCoverables: null,
    coverablesLoaded: false,

    quotesActiveFilter: {amount: 10000, period:90, protocol: null, currency:"USD"}, //quotation filter
    quotesBasicCurrencies: ["ETH" , "USD"],
    periods: [30 , 90],
    buyCoverQuotes: [], //quotations

    activeCovers: [],
    coversLoaded: false,

  }),
  mutations: {
    brightClientInitialized(state){
      state.brightClientActive = true;
    },
    quotesActiveFilterUpdate(state , payload){
      state.quotesActiveFilter = payload;
    },
    allCoverablesLoaded(state, payload) {
      state.allCoverables = payload.items;
      state.coverablesLoaded = !payload.fromCache;
      localStorage.setItem('catalog', JSON.stringify( { items: payload.items , version: payload.version, timeStamp: new Date() } ) );
    },
    resetBuyCoverQuotes(state) {
       state.buyCoverQuotes = [];
    },
    allQuotesLoaded(state , payload) {
       state.buyCoverQuotes = payload;
    },
    activeCoversLoaded (state, payload) {
      state.coversLoaded = true;
      state.activeCovers = payload;
    },
    coversReset (state) {
      state.coversLoaded = false;
      state.activeCovers = [];
    }
  },
  actions: {

    async initBrightClient({ state , commit, dispatch, rootState } , web3){
      let isEmbed = rootState.web3.embed ? '_embed_' + rootState.web3.embed : '' ;

      state.brightClient = new BrightClient(
        {
          web3: web3,
          clientKey: "BrightUnion_UI_" + process.env.NODE_ENV + isEmbed ,
          GADisable: process.env.NODE_ENV == "production" ?  false : true // disable Google Analytics for development
        }
      );

      dispatch("getCatalogLocalStore");

      let urlParams = new URLSearchParams(window.location.search);
      const response = await state.brightClient.initialize(
          urlParams.has('addr') ? urlParams.get('addr') : null);

      if(!response.initialized && response.error.includes("network")){
        EventBus.publish('SHOW_NETWORK_SWITCH_MODAL', {show:true, newNet:"Ethereum"} );
      }else {
        commit("brightClientInitialized" );
        return response;
      }

    },
    getCatalogLocalStore({commit, state}){

      let catalogLocal = JSON.parse( localStorage.getItem('catalog') );

      if(!catalogLocal || (catalogLocal.items && catalogLocal.items.error) ) return;

      const now = new Date();
      const catalogExpire = moment(catalogLocal.timeStamp).add('days' , 10);
      const catalogExpired = moment(now).isAfter( catalogExpire );

      if(catalogLocal.version == state.brightClient.getVersion() && !catalogExpired ){
        catalogLocal.fromCache = true;
        commit('allCoverablesLoaded', catalogLocal );
      }
    },
    async getCatalog({state, commit }){

      EventBus.publish('TRACK_EVENT_COMMERCE', {eventName: 'view_item_list' , data: {} });

      let catalog = await state.brightClient.getCatalog();
      catalog.fromCache = false;
      commit('allCoverablesLoaded', catalog );
    },
    async getQuotes({state, commit}, {amount, currency, period, protocol}){

      EventBus.publish('TRACK_EVENT_COMMERCE', { eventName: 'select_item' , data: {amount, currency, period, protocol} });

      commit('resetBuyCoverQuotes');
      const buyCoverQuotes = await state.brightClient.getQuotes( amount , currency , period , protocol );

      const isActiveFilter = () => {
        let recent = false;
        for (var i = 0; i < buyCoverQuotes.length; i++) {
          if(buyCoverQuotes[i].name && buyCoverQuotes[i].name == state.quotesActiveFilter.protocol.name){
            recent = true;
            break;
          }
        }
        return recent;
      };

      if(isActiveFilter()) {
        commit('allQuotesLoaded' ,  buyCoverQuotes );
      }
    },

    async getQuoteFrom( { state } , {quote} ){
      return state.brightClient.getQuoteFrom( quote.distributorName, quote.amount , quote.currency , quote.period , quote.protocol, quote.owner );
    },

    buyQuote( { state } , {quote} ){
      state.brightClient.buyQuote(quote);
    },

    async getAllCovers({state,commit}){
      commit("coversReset");
      let covers = await state.brightClient.getAllCovers();
      commit("activeCoversLoaded" , covers)
    },

  }, // actions
  getters: {
    activeCovers: (state) => {
      let covers = Object.values(state.activeCovers).flat().sort((a, b) => {
        return Number(a.validUntil) - Number(b.validUntil);
      })
      return covers;
    },
    coverableByAddress: () => (address , coverablesArray) => {
      if( coverablesArray.length == 0 || !address) return;
        const found = coverablesArray.find((a) => {return a.protocolAddress && a.protocolAddress.toUpperCase() === address.toUpperCase()});
        if (found) {
            return found;
        } else {
            console.error('Can\'t find coverable with address ' + address);
        }
    },
    coverableByName: () => (name , coverablesArray) => {
      if(coverablesArray.length == 0 || !name) return;
        const found = coverablesArray.find(a => a.name && a.name.toUpperCase() === name.trim().toUpperCase());
        if (found) {
            return found;
        } else {
            console.error('Can\'t find coverable with name ' + name);
        }
    },
    coverableByNexusProductId: () => (productId , coverablesArray) => {
      if(coverablesArray.length == 0 || !productId) return;
      const found = coverablesArray.find(a => a.nexusProductId && Number(a.nexusProductId) === Number(productId));
      if (found) {
        return found;
      } else {
        console.error('Can\'t find coverable with this Nexus product id ' + productId);
      }
    },
    getCoverable: (state, getters) => (_cover , _riskProtocol ) => {
      let cov = false;
      const coverablesArray = state.allCoverables;
      if (_riskProtocol === 'nexus') {
        cov = getters.coverableByNexusProductId(_cover.nexusProductId, coverablesArray);
      } else if (_riskProtocol === 'insurace') {
        cov = getters.coverableByName(_cover.contractName ? _cover.contractName : _cover.name , coverablesArray);
      } else if (_riskProtocol === 'ease') {
        cov = getters.coverableByName(_cover.contractName ? _cover.contractName : _cover.name , coverablesArray);
      } else if (_riskProtocol === 'bridge') {
        cov =  getters.coverableByName(_cover.name , coverablesArray);
      } else if (_riskProtocol === 'unslashed') {
        cov =  getters.coverableByName(_cover.name , coverablesArray);
      }
      return cov;
    },
  }

};

export {moduleCovers}
