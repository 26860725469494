import {BRI_PLATFORMS, getIndexAddress, nexusStakingViewer} from '@/store/bri_config'
import ExplorerApi from '@/services/ExplorerApi'
import moment from 'moment';
import ExchangeRateAPI from "@/services/ExchangeRateAPI";
import {
  getBrightRiskTokenContract,
  getNexusStakingViewer,
  getNexusStakingPool,
  getAbstractControllerContract,
  getInsuraceControllerContract,
  getERC20PermitContract,
  getInsurAceStakersPoolContract,
  getNexusControllerContract,
  getBridgeControllerContract,
  getBridgeLeveragedPortfolioContract, getIERC20Contract,
} from "@/utils/getContract";
import { fromWei, toWei , toBN} from '@/utils/filters.js';
import {sixDecimalsCurrency, netById} from '@/store/network_config'
import ERC20Helper from '@/services/ERC20Helper'

const moduleBRI = {
  state: () => ({
    indexTVL: null,
    indexAddress: '',

    indexInstance: null,
    indexBaseAssetAddress: null,
    indexBaseTokenInstance: null,
    indexTokenSymbol: '',
    indexTokenValue: '0',
    externalPool: '0',
    externalPoolInBRI: '0',
    depositMin: '0',
    depositFee: '0',

    platforms: BRI_PLATFORMS,
    // total worth of protocols, denominated in 'base'
    totalWorth: 0,
    // Worth per controller
    controllersWorth: {},
    // amount per asset
    assetExposure: {},
    // percent per asset
    assetExposurePercent: null,
    // APY per controller
    controllersAPY: {},
    indexMomentaryAPY: null,
    indexHistoricAPY: 0,
    txHistory: [],
    lastStake: null,

    convertedBalance: 0,
    briBalance: null,

    bridgeProducts: [],

    /* Nexus */
    nexusProducts: [],
    nexusStake: {},

    apolloProvider: null,

  }),

  mutations: {

    setBRITotalWorth(state, payload){
      if( payload === false ){
        state.totalWorth = 0;
      } else {
        state.totalWorth += Number(payload);
      }
    },
    setHistoricAPY(state, payload){
      state.indexHistoricAPY = payload;
    },
    setPlatformsFetchedProtocols(state, payload){
      for (const key in state.platforms) {
        state.platforms[key].fetchedProtocols = payload[key];
      }
    },
    setPlatformsAssets(state , payload){
      for (const key in state.platforms) {
        state.platforms[key].assets = payload[key];
      }
    },
    resetProtocolsWorth(state){
      for (const key in state.platforms) {
        state.platforms[key].protocolWorth = 0;
      }
    },
    addControllerAddress(state, payload){
      for (const key in state.platforms) {
        if(payload[key]){
          state.platforms[key].controllerAddress.push(payload[key]);
        }
      }
    },
    resetControllerAddress(state){
      for (const key in state.platforms) {
        state.platforms[key].controllerAddress = [];
      }
    },
    addControllerWorth(state , payload){
        state.controllersWorth[payload.position] = payload.worth;
    },
    resetControllersWorth(state ){
        state.controllersWorth = {};
    },
    addControllerAPY(state , payload){
      state.controllersAPY[payload.position] = payload.apy;
    },
    resetControllersAPY(state ){
      state.controllersAPY = {};
    },
    resetAssetExposure(state ){
        state.assetExposure = {};
    },
    resetTxHistory(state ){
        state.txHistory = [];
    },
    setBriBalance(state , payload ){
        state.briBalance = payload;
    },
    setExternalPool(state, payload){
      state.externalPool = payload;
      state.externalPoolInBRI = payload / state.indexTokenValue;
    },
  },
  actions: {
    addProtocolWorth ({state}, {protocol, amount}) {
      let currentWorth = state.platforms[protocol].protocolWorth;
      if (currentWorth) {
        state.platforms[protocol].protocolWorth = currentWorth + Number(amount);
      } else {
        state.platforms[protocol].protocolWorth = Number(amount);
      }
    },
    setPlatformsExposure ({state}, setTo) {
      for (const key in state.platforms) {
        state.platforms[key].exposure = setTo ? setTo : (state.platforms[key].protocolWorth * 100 / state.totalWorth).toFixed(0);
      }
    },
    async addAssetExposure ({state}, {asset, amount}) {
      let currentExposure = state.assetExposure[asset];
      if (!asset.includes('USD') && !asset.includes('DAI')) {
        await ExchangeRateAPI.fetchExchangeRate(asset.toUpperCase() === 'NXM' ? 'WNXM' : asset.toUpperCase()).then(rate => {
          amount = amount * Number(rate);
        })
      }
      if (currentExposure) {
        state.assetExposure[asset] = currentExposure + Number(amount);
      } else {
        state.assetExposure[asset] = Number(amount);
      }
    },
    getTotalSupplies ({state, dispatch}) {
      let totalAssetExposure = 0;
      for (const key in state.assetExposure) {
        totalAssetExposure += state.assetExposure[key];
      }
      const tempObject = {};
      for (const [key, value] of Object.entries(state.assetExposure)) {
        tempObject[key] = (value / totalAssetExposure * 100).toFixed(1);
      }
      state.assetExposurePercent = Object
          .entries(tempObject)
          .sort((a, b) => b[1] - a[1])
          .reduce((_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v
          }), {})

      for (const [controller, worth] of Object.entries(state.controllersWorth)) {
        const apy = state.controllersAPY[controller] * (worth / state.totalWorth)
        if (!isNaN(apy)) {
          state.indexMomentaryAPY += apy;
        }
      }

      dispatch("setPlatformsExposure");
    },
    nullifyExposure ({state, commit, dispatch}) {

      state.indexMomentaryAPY = 0;
      state.indexHistoricAPY = 0;
      state.assetExposurePercent = null;
      commit("resetAssetExposure");
      commit("resetControllersAPY");
      commit("resetControllersWorth");
      commit("setBRITotalWorth", false);
      commit("resetProtocolsWorth");
      commit("resetTxHistory");
      dispatch("setPlatformsExposure", "0");
      state.nexusProducts = [];

    },
    initializeBRI ({state, getters, dispatch, commit, rootState}, {apolloProvider}) {
      //BRI
      state.apolloProvider = apolloProvider;
      state.indexAddress = getIndexAddress(getters.ethNetId);

      getBrightRiskTokenContract(state.indexAddress, getters.xWeb3Eth).then(instance => {
        state.indexInstance = instance;
        state.indexInstance.methods.depositFee().call().then(fee => {
          state.depositFee = fee / 100000;
        })
        state.indexInstance.methods.countPositions().call().then(positionsNumber => {

          dispatch("nullifyExposure");

          state.indexInstance.methods.listPositions(0, positionsNumber).call().then(positionsArr => {

            commit("resetControllerAddress");

            Promise.all(
                positionsArr.map(position => {
                  return getAbstractControllerContract(position, getters.xWeb3Eth).then(positionInfo => {
                    return positionInfo.methods.description().call().then(posDescription => {
                      return positionInfo.methods.netWorth().call().then(async (worth) => {

                        commit("setBRITotalWorth", fromWei(worth));
                        commit("addControllerWorth", {position: position, worth: Number(fromWei(worth))});

                        if (posDescription.toLowerCase().includes('bridge')) {
                          commit("addControllerAddress", {bridge: position});
                          dispatch("addProtocolWorth", {protocol: 'bridge', amount: fromWei(worth)});
                          await dispatch("loadBridgeContract", {address: position});
                        } else if (posDescription.toLowerCase().includes('nexus')) {
                          commit("addControllerAddress", {nexus: position});
                          dispatch("addProtocolWorth", {protocol: 'nexus', amount: fromWei(worth)});
                          await dispatch("loadNexusContract", {address: position});
                        } else {
                          commit("addControllerAddress", {insurace: position});
                          dispatch("addProtocolWorth", {protocol: 'insurace', amount: fromWei(worth)});
                          await dispatch("loadInsuraceContract", {address: position});
                        }
                        return true
                      })
                    })
                  })
                })
            ).then(() => {
              state.indexInstance.methods.externalPool().call().then(async (externalPool) => {
                commit("setExternalPool", fromWei(externalPool))
                //virtual position, which gains 0% APY
                const position = '0x0000000000000000000000000000000000000000';
                commit("addControllerWorth", {position: position, worth: Number(state.externalPool)});
                commit("addControllerAPY", {position: position, apy: 0});

                dispatch("getTotalSupplies");

                if (!rootState.covers.allCoverables) {
                  await dispatch("getCatalog");
                }

                dispatch("loadAssetLogos");

              });
            })
          })
        })
        state.indexInstance.methods.symbol().call().then(symbol => state.indexTokenSymbol = symbol);
        state.indexInstance.methods.base().call().then(baseAddress => {
          // base asset
          state.indexBaseAssetAddress = baseAddress;
          getIERC20Contract(baseAddress, getters.xWeb3Eth).then(baseInstance => {
            state.indexBaseTokenInstance = baseInstance;
          });
        });

        state.indexInstance.methods.totalTVL().call().then((tvl) => {
          state.indexTVL = fromWei(tvl)
        });

        state.indexInstance.methods.convertIndexToInvestment(toWei(1)).call().then(value => {
          state.indexTokenValue = Number(fromWei(value)).toFixed(5);
          state.indexInstance.methods.balanceOf(getters.myAddress).call().then(balance => {
            let briBalance = fromWei(balance);
            commit("setBriBalance", briBalance);
            state.convertedBalance = briBalance * state.indexTokenValue
          });
        });

        //state.indexInstance.methods.externalPool().call().then(externalPool => commit("setExternalPool", fromWei(externalPool)));
        state.indexInstance.methods.minimumBaseDeposit().call().then(min => state.depositMin = fromWei(min));
        // DEPRECATED
        //dispatch("loadUserBRITransactions", {indexInstance: state.indexInstance, web3Active: getters.xWeb3Eth})
      })
    },
    loadInsuraceContract ({dispatch, commit, getters}, {address}) {
      return getInsuraceControllerContract(address, getters.xWeb3Eth).then((instance) => {
        return instance.methods.stakingAsset().call().then(asset => {
          return getERC20PermitContract(asset, getters.xWeb3Eth).then(assetInstance => {
            return assetInstance.methods.symbol().call().then(assetName => {
              return instance.methods.positionSupply().call().then(positionSupply => {
                if (sixDecimalsCurrency(getters.ethNetId, assetName)) {
                  positionSupply = fromWei(ERC20Helper.USDTtoERCDecimals(positionSupply));
                } else {
                  positionSupply = fromWei(positionSupply);
                }
                dispatch("addAssetExposure", {asset: assetName, amount: positionSupply});
                instance.methods.outstandingRewards().call().then(rewards => {
                  dispatch("addAssetExposure", {asset: 'INSUR', amount: fromWei(rewards)});
                });
                return instance.methods.lpToken().call().then(lpToken => {
                  return instance.methods.stakingAsset().call().then(stakingAsset => {
                    return getERC20PermitContract(stakingAsset, getters.xWeb3Eth).then(assetInstance => {
                      return assetInstance.methods.decimals().call().then(stakingDecimals => {
                        return getInsurAceStakersPoolContract(netById(getters.ethNetId).insuraceStakersPool, getters.xWeb3Eth).then(stakersPool => {
                          return stakersPool.methods.rewardPerBlock().call().then(_rewardsPB => {
                            return stakersPool.methods.poolWeightPT(lpToken).call().then(_poolWeight => {
                              return stakersPool.methods.totalPoolWeight().call().then(_totalPoolWeight => {
                                const _rewardsPerBlockPerPool = toBN(_poolWeight).mul(toBN(_rewardsPB)).div(toBN(_totalPoolWeight));
                                const _rewardsPerYear = _rewardsPerBlockPerPool.mul(toBN(Math.round(356 * 24 * 3600 / 13)));

                                return stakersPool.methods.getStakedAmountPT(stakingAsset).call().then(_poolTVLInStakingAsset => {
                                  return ExchangeRateAPI.fetchExchangeRate('INSUR').then(_stakingInOneInsur => {
                                    let _insurInOneStaking = 1 / Number(_stakingInOneInsur);
                                    let _poolTVLInStakingAssetInUnits = fromWei(_poolTVLInStakingAsset, Number(stakingDecimals) == 18 ? 'ether' : 'mwei');
                                    const _poolTVLInRewardsAssetInUnitsWithPrecision = Number(_poolTVLInStakingAssetInUnits) * _insurInOneStaking;
                                    const apy = Number(fromWei(_rewardsPerYear, 'ether')) /
                                        _poolTVLInRewardsAssetInUnitsWithPrecision * 100;
                                    commit("addControllerAPY", {position: address, apy: apy})
                                  });
                                });
                              });
                            });
                          })
                        })
                      })
                    })
                  });
                });
              });
            });
          });
        });

      });
    },
    loadNexusContract ({dispatch, state, commit, getters}, {address}) {
      return getNexusControllerContract(address, getters.xWeb3Eth).then(instance => {
        return getNexusStakingViewer(nexusStakingViewer, getters.xWeb3Eth).then(stakingViewer => {
          /*return stakingViewer.methods.getPoolProducts('4').call().then(products => {
            console.log(products)

          })*/
          //FIXME hardcoded
          state.nexusProducts = [2, 72, 75, 77, 67, 73, 64, 65, 66, 38, 44, 59, 31, 33, 36, 22, 26, 29, 6, 8, 10, 11, 14, 20];

          return instance.methods.outstandingRewards().call().then(rewards => {
            return instance.methods.maxUnstake().call().then(stake => {
              state.nexusStake[address] = Number(stake) + Number(rewards);
              return stake;
            })
          }).then(async () => {
            await dispatch("addAssetExposure", {asset: 'NXM', amount: fromWei(state.nexusStake[address])});
            return stakingViewer.methods.stakingPool('4').call().then(poolAddress => {
              return getNexusStakingPool(poolAddress, getters.xWeb3Eth).then(stakingPoolInstance => {
                return stakingPoolInstance.methods.getActiveStake().call().then(stakeNXM => {
                  return stakingPoolInstance.methods.getRewardPerSecond().call().then(rewardPerSecondNXM => {
                    const apyThousands = toBN(rewardPerSecondNXM).mul(toBN(Number(3600 * 24 * 365 * 100000).toString())).div(toBN(stakeNXM));
                    const apy = Number(apyThousands.toString()) / 1000;
                    commit("addControllerAPY", {position: instance.options.address, apy: apy.toString()})
                  })
                })
              })
            })
          })

        });
      });
    },
    loadBridgeContract ({commit, getters, dispatch, state}, {address}) {
      return getBridgeControllerContract(address, getters.xWeb3Eth).then(instance => {
        state.bridgePosition = instance;
        state.bridgePosition.methods.outstandingRewards().call().then(rewards => {
          dispatch("addAssetExposure", {asset: 'BMI', amount: fromWei(rewards)});
        })
        return state.bridgePosition.methods.maxUnstake().call().then(bmiX => {
          return state.bridgePosition.methods.leveragedPortfolio().call().then(leveragedPortfolioAddress => {
            return getBridgeLeveragedPortfolioContract(leveragedPortfolioAddress, getters.xWeb3Eth).then(leveragedPortfolio => {
              return leveragedPortfolio.methods.convertBMIXToSTBL(bmiX).call().then(usdt => {
                dispatch("addAssetExposure", {asset: 'USDT', amount: fromWei(usdt)});
                return state.bridgePosition.methods.productList().call().then(products => {
                  state.bridgeProducts = products;

                  state.bridgePosition.methods.apy().call().then(apy => {
                    commit("addControllerAPY", {position: address, apy: Number(apy) / 10 ** 5})
                  })

                });
              });
            })
          })
        });
      })
    },
    async loadUserBRITransactions ({state, getters} , {indexInstance, web3Active}){
              const threeMonthAgo           = Number(await web3Active.eth.getBlockNumber()) - 4 * 60 * 24 * 90;
              const deposits                = await ExplorerApi.web3FetchUserTxByIndexEvent({depositor : getters.myAddress },web3Active,indexInstance,'IndexDeposit', threeMonthAgo);
              const stakes                  = await ExplorerApi.web3FetchUserTxByIndexEvent(null,web3Active, indexInstance,'Stake', threeMonthAgo);
              const unstakes              = await ExplorerApi.web3FetchUserTxByIndexEvent(null,web3Active,indexInstance,'Unstake', threeMonthAgo);
              const withdrawals           = await ExplorerApi.web3FetchUserTxByIndexEvent({sender: getters.myAddress},web3Active,indexInstance,'IndexBurn', threeMonthAgo);
              const lastBlock               = stakes.length > 0 && await web3Active.eth.getBlock(stakes[stakes.length - 1].blockNumber)
              state.lastStake             = lastBlock > 0 ?  moment.unix(lastBlock.timestamp) : 0;
              const unstakePromise        = new Promise((resolve) => {
              let logs = [];
                unstakes && unstakes.forEach((unstake) => {
                    ExplorerApi.fetchTx(web3Active, unstake.briEvent.transactionHash).then(
                      tx => {
                        if(tx.from === getters.myAddress){
                          logs.push(new Object({
                            date: unstake.date,
                            blockNumber: unstake.blockNumber,
                            type:"Unstake",
                            amount: unstake.briEvent.returnValues.amount,
                            price:null,
                            briAmount: null
                        }));
                        }
                      }
                    )
                  });
                resolve(logs);
            });


            const withdrawalsPromise = new Promise((resolve) => {
              let logs = [];
                withdrawals && withdrawals.forEach((withdraw) => {
                let baseAmount = Number(fromWei(withdraw.briEvent.returnValues.baseAmount));
                let indexAmount = Number(fromWei( withdraw.briEvent.returnValues.indexAmount))
                    logs.push(new Object({
                          date:  withdrawals.date,
                          blockNumber: withdraw.blockNumber,
                          type:"Withdraw",
                          amount: Math.round(baseAmount),
                          price: Number(baseAmount / indexAmount),
                          briAmount: String(indexAmount).includes('.') ?
                                     Number(indexAmount).toFixed(2) : Math.round(Number(indexAmount))
                      }));
                  });
              resolve(logs);
            });

          const depositPromise = new Promise((resolve) => {
                  let logs = [];
                  deposits && deposits.forEach( (deposit) => {

                    const amount = fromWei(deposit.briEvent.returnValues.amount);
                    const mintAmount = deposit.briEvent.returnValues.mintAmount.length > 1 ?
                                       fromWei(deposit.briEvent.returnValues.mintAmount) :
                                       deposit.briEvent.returnValues.mintAmount;

                    logs.push(new Object({
                          date: deposit.date,
                          blockNumber: deposit.blockNumber,
                          type:"Deposit",
                          amount: amount,
                          price:  Number(amount/mintAmount),
                          briAmount: Number(mintAmount).toFixed(2)
                         }));
                    });
               resolve(logs);
            });

        Promise.all([depositPromise,withdrawalsPromise,unstakePromise])
           .then((logs) => {
             return logs.flat();
          }).then(async(res) => {
              let txHistory = [];
               for (const event of res) {
                 const block = await web3Active.eth.getBlock(event.blockNumber);
                 const date = moment.unix(block.timestamp);
                 const briPrice = '$' + Number(event.price).toFixed(2);
                 const briMinted = event.briAmount + ' BRI';
                 txHistory.push({
                     date : date.format( "MM-DD-YYYY HH:MM:ss" ),
                     type: event.type,
                     amount: (Number((event.amount))) + ' DAI',
                     price: briPrice,
                     briAmount: briMinted,
                   });
              }
              txHistory.sort((a,b) => new Date(b.date)- new Date(a.date));
              state.txHistory = txHistory;
          });
    },
    async loadAssetLogos({state, getters, commit, rootState}) {

      let insuraceArr = [];
      let insuraceFullArr = [];
      let nexusFullArr = [];
      let bridgeFullArr = [];
      const mirrorFinance = 'https://files.insurace.io/public/asset/product/MirrorFinance.png';
      const ustDepeg = 'https://files.insurace.io/public/asset/product/USTDepeg.png';
      const beefy = 'https://files.insurace.io/public/asset/product/BeefyFinance.png';
      insuraceFullArr = [
        {logo: mirrorFinance, name: 'Mirror Finance'},
        {logo: ustDepeg, name: 'UST De-peg'},
        {logo: beefy, name: 'Beefy Finance'}
      ];

      let logoCounter = 4;
      while(logoCounter != 0 ){
        logoCounter--;
      }

      bridgeFullArr = [
        {logo: 'https://files.insurace.io/public/asset/product/AaveV2.png', name: 'Aave V2'},
        {logo: 'https://files.insurace.io/public/asset/product/ConvexFinance.png', name: 'Convex'},
        {logo: 'https://raw.githubusercontent.com/Uno-Re/cover-products-info/main/logos/curve_logo.png', name: 'Curve'}
      ];
      for(let i = 0; i < 4; i ++) {
        let coverableNexus = getters.getCoverable( { nexusProductId: state.nexusProducts[i] } , 'nexus'  );
        if (coverableNexus) {
          nexusFullArr.push({logo: coverableNexus.logo, name: coverableNexus.name})
        }
      }

      rootState.covers.allCoverables.forEach(cover => {
        if(cover.source === 'insurace') {
          insuraceArr.push({logo: cover.logo, name: cover.name})
        }
      });

      commit("setPlatformsAssets" , { bridge: bridgeFullArr , insurace: insuraceFullArr.concat(insuraceArr), nexus: nexusFullArr} );
      commit("setPlatformsFetchedProtocols" , { bridge: state.bridgeProducts.length , insurace: insuraceArr.length, nexus: state.nexusProducts.length} );
    },
  }, // actions
  getters: {
  }

};

export {moduleBRI}
