<template>
  <div id="app">
    <router-view/>
    <TrackingTag />
  </div>
</template>

<script>
import TrackingTag from "@/components/TrackingTag";

export default {
  components: {
    TrackingTag
  },
}
</script>
<style lang="scss" >


.app-loader{
  transition-delay: 0.75s;
  opacity: 0;
  z-index: -1;
}

</style>
