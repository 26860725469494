<template>
  <div></div>
</template>
<script>

// <!-- Twitter universal website tag code -->
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version="1.1",s.queue=[],u=t.createElement(n),u.async=!0,u.src="https://static.ads-twitter.com/uwt.js",
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))
// Insert Twitter Pixel ID and Standard Event data below

if(window.origin == "https://app.brightunion.io" ){
  e.twq('init','o7d0p');
  e.twq('track','PageView');
}

}(window,document,'script');
// <!-- End Twitter universal website tag code -->

import EventBus from "@/utils/event-bus";
import { fromWei } from '@/utils/filters.js';

export default {
  name: "TrackingTag",
  beforeCreate() {
    EventBus.subscribe('TRACK_EVENT', (data) => {
      this.track(data.action, data.category, data.label, data.value);
    });
    EventBus.subscribe('TRACK_EVENT_COMMERCE', (data) => {
      if(data && data.data){
        this.commerce(data.eventName , data.data);
      }
    });
  },
  methods: {
    track(action, category, label, value) {
      this.$gtag.event(action, {
        'event_category': category,
        'event_label': label,
        'value': value
      })
    },
    commerce(eventName, data) {

      let eventData = {};

      if( eventName == "purchase" ){

        eventData = {
          'transaction_id': data.hash,
          'affiliation': "Bright app ui",
          'value': fromWei(data.premium),
          'tax': 0,
          'shipping': 0,
          'items': [
            {
              'item_id': data.productId,
              'item_name': data.name,
              'affiliation': "Bright app ui",
              'item_brand': data.distributor,
              'item_variant': fromWei(data.amount),
              'price': fromWei(data.premium),
              'quantity': data.period,
              'item_category': global.user.symbol,
              'item_category3': data.currency,
              'item_list_id': 1,
              'item_list_name': 'catalog',
            }
          ]
        }

      }else if(eventName == "add_to_cart" || eventName == "select_item") {

        eventData = {
          'item_list_id': data.protocol.name,
          'item_list_name': data.protocol.name,
          'items': [
            {
              'item_id': data.protocol.name,
              'item_name': data.protocol.name,
              'affiliation': "Bright app ui",
              'item_brand': eventName == "add_to_cart" ? data.distributorName : "",
              'item_variant': eventName == "add_to_cart" ? fromWei(data.amount) : data.amount ,
              'price': eventName == "add_to_cart" ? fromWei(data.price) : '0',
              'quantity': data.period,
              'item_category2': data.protocol.type,
              'item_category3': data.currency,
              'item_category4': eventName == "add_to_cart" ? data.chain : null,
              'item_list_id': 1,
              'item_list_name': 'catalog',
            }
          ]
        }

      }else if(eventName == "view_item_list"){

        eventData = {
          'item_list_name': "catalog",
          'item_list_id': 1,
          'items':[
            {
              'item_id': 1,
              'item_name': "catalog item"
            }
          ],
        }

      }

      eventData.currency = "EUR";
      window.dataLayer.push({ 'event': eventName, 'ecommerce': eventData });
    },
  }
}
</script>

<style scoped>

</style>
