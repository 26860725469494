import Vue from 'vue'
import VueRouter from 'vue-router'
import BackgroundLayout from "@/views/layouts/BackgroundLayout";
import CoveragesBg from "@/assets/img/bg.png";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/Main.vue'),
        title: 'Main',
        meta: {
            requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED
        },
        children: [
            {
                path: '/',
                redirect: '/portfolio',
            },
            {
                path: '/portfolio',
                name: 'Portfolio',
                meta: {
                    requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED
                },
                component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio.vue')
            },
            {
                path: '/coverages',
                name: 'Coverages',
                meta: {
                    requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED,
                    layout: BackgroundLayout,
                    bgImage: CoveragesBg
                },
                component: () => import(/* webpackChunkName: "coverages" */ '../views/Coverages.vue')
            },
            {
                path: '/provide-cover',
                name: 'BrightRiskIndex',
                meta: {
                    requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED
                },
                component: () => import(/* webpackChunkName: "bri" */ '../views/BrightRiskIndex.vue')
            },
            {
                path: '/staking-bright',
                name: 'StakingBright',
                meta: {
                    requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "stakingbright" */ '../views/StakingBright.vue')
            }
        ]
    },
    {
        path: '/protected',
        name: 'Protected',
        component: () => import(/* webpackChunkName: "protected" */ '../views/Protected')
    },
    { path: '*', redirect: '/' }
]

if (process.env.NODE_ENV !== 'production') {
    routes[0].children.push(
        {
            path: '/playground',
            name: 'Playground',
            meta: {
                requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED
            },
            component: () => import(/* webpackChunkName: "playground" */ '../views/Playground.vue')
        },
        {
            path: '/index-playground',
            name: 'IndexPlayground',
            meta: {
                requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED
            },
            component: () => import(/* webpackChunkName: "briplayground" */ '../views/IndexPlayground.vue')
        },
      {
          path: '/analytics',
          name: 'Analytics',
          meta: {
              requiresAuth: process.env.VUE_APP_PASSWORD_PROTECTED
          },
          component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics')
      }
    );
}

const router = new VueRouter({
    mode: 'history',
    routes
})

// router.afterEach(() => {
// });

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiresAuth && route.meta.requiresAuth === 'true')) {
        const password = localStorage.getItem('password');
        if (password) {
            return next();
        }
        return next('/protected');
    }
    next();
});

export default router
