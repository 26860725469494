const  Web3 = require("web3");
const QUICKNODEPROVIDER = "https://eth-mainnet.g.alchemy.com/v2/OlIDDqLH9Uo3AUQ_0ezj6sfqHIGxJRxw/"

const NETWORK_CONFIG = [
    {
        name: 'Ethereum',
        id: 1,
        symbol: 'ETH',
        defaultCurrency: "ETH",
        explorer: 'https://etherscan.io',
        explorerApi: 'https://api.etherscan.io',
        //providerForHistory: 'https://eth-mainnet.alchemyapi.io/v2/OlIDDqLH9Uo3AUQ_0ezj6sfqHIGxJRxw',
        providerForHistory: 'https://eth.llamarpc.com/rpc/01GPB4WFEZFW0ATPTH5YXSAN91',
        provider: QUICKNODEPROVIDER,
        modules: process.env.VUE_APP_MAINNET_MODULES.split(' '),
        bridgeRegistry: '0x8050c5a46FC224E3BCfa5D7B7cBacB1e4010118d',
        nexusDistributorV1: '0x3756C3C9374f38e0d9aAcB637Fed1641504a5b28',
        nexusDistributor: '0x425b3a68f1fd5de26b4b9f4be8049e36406b187a',
        nexusGateWay:'0x089Ab1536D032F54DFbC194Ba47529a4351af1B5',
        nexusAPI: 'https://api.nexusmutual.io',
        brightContractRegistry: '0xAA66e85682429a29363556ef757c44AfC5000F00',
        insuraceCover: '0x88Ef6F235a4790292068646e79Ee563339c796a0',
        insuraceStakersPool: '0x136D841d4beCe3Fc0E4dEbb94356D8b6B4b93209',
        insuraceAPI: 'https://api.insurace.io/ops/v1',
        insuraceAPIKey: 'H7C8k69Eiisz7AG1/6xcI5UWGluTtyAbizXrsfbfQIBDapQZEHAHFw==',
        insuraceReferral: '982107115070280393099561761653261738634756834311',
        ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        WETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
        USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        WNXM: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
        INSUR:'0x544c42fbb96b39b21df61cf322b5edc285ee7429',
        BMI:'0x725C263e32c72dDC3A19bEa12C5a0479a81eE688',
    },
    {
        name: 'Ethereum-fork',
        id: 1337,
        symbol: 'ETH',
        defaultCurrency: "ETH",
        explorer: 'https://etherscan.io',
        explorerApi: 'https://api.etherscan.io',
        provider: 'https://eth-mainnet.alchemyapi.io/v2/OlIDDqLH9Uo3AUQ_0ezj6sfqHIGxJRxw',
        modules: process.env.VUE_APP_MAINNET_MODULES.split(' '),
        bridgeRegistry: '0x8050c5a46FC224E3BCfa5D7B7cBacB1e4010118d',
        nexusDistributor: '0x425b3a68f1fd5de26b4b9f4be8049e36406b187a',
        nexusGateWay:'0x089Ab1536D032F54DFbC194Ba47529a4351af1B5',
        nexusAPI: 'https://api.nexusmutual.io',
        brightContractRegistry: '0xAA66e85682429a29363556ef757c44AfC5000F00',
        insuraceCover: '0x88Ef6F235a4790292068646e79Ee563339c796a0',
        insuraceStakersPool: '0x136D841d4beCe3Fc0E4dEbb94356D8b6B4b93209',
        insuraceAPI: 'https://api.insurace.io/ops/v1',
        insuraceAPIKey: 'H7C8k69Eiisz7AG1/6xcI5UWGluTtyAbizXrsfbfQIBDapQZEHAHFw==',
        insuraceReferral: '982107115070280393099561761653261738634756834311',
        ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
        USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        INSUR:'0x544c42fbb96b39b21df61cf322b5edc285ee7429',
        BMI:'0x725C263e32c72dDC3A19bEa12C5a0479a81eE688',
    },
    {
        name: 'Ropsten',
        id: 3,
        symbol: 'ETH',
        defaultCurrency: "ETH",
        explorer: 'https://ropsten.etherscan.io',
        explorerApi: 'https://api-ropsten.etherscan.io',
        provider: 'https://ropsten.infura.io/v3/98d7e501879243c5877bac07a57cde7e',
        nexusAPI: 'https://api.nexusmutual.io',                 //not used
        brightContractRegistry: '',                             //not used
        insuraceCover: '',                                      //not used
        insuraceStakersPool: '',                                //not used
        insuraceAPI: '',                                        //not used
        insuraceAPIKey: '',                                     //not used
        insuraceReferral: '',
        modules: process.env.VUE_APP_ROPSTEN_MODULES.split(' '),
        ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        DAI: '0x69dDdb0F010D9Cac595F75d8b0fff59CDCD1D44f',      //not used
        USDT: '0xcc54b12a18f2c575ca97991046090f43c3070aa0',     //not used
        USDC: '',                                               //not used
        INSUR: '',                                               //not used
    },
    {
        name: 'Rinkeby',
        id: 4,
        symbol: 'ETH',
        defaultCurrency: "ETH",
        explorer: 'https://rinkeby.etherscan.io',
        explorerApi: 'https://api-rinkeby.etherscan.io',
        provider: 'https://rinkeby.infura.io/v3/98d7e501879243c5877bac07a57cde7e',
        nexusAPI: 'https://api.nexusmutual.io',                 //not used
        brightContractRegistry: '',                             //not used
        insuraceCover: '0x0921f628b8463227615D2199D0D3860E4fBcD411',
        insuraceStakersPool: '0x38e8D3D7C8a1169e3Cc77BD6049AD965bF60A672',
        insuraceAPI: 'https://insurace-sl-microservice.azurewebsites.net',
        insuraceAPIKey: 'OmgwnM39a/M9/9Q856wbRkILcYh2ZmlJPpG9cVxT5Vy6aR8eNl3/jw==',
        insuraceReferral: '',
        modules: process.env.VUE_APP_RINKEBY_MODULES.split(' '),
        bridgeRegistry: '0x0Ac28BcDcef8D8C95c4a079418dbC34e4AD4DF1D',
        ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        DAI: '0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea',
        USDT: '0xD92E713d051C37EbB2561803a3b5FBAbc4962431',
        USDC: '0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b',
        INSUR: '0xde26469d4334059983ccc61251532c76cf5fbbb7',
    },
    {
        name: 'Kovan',
        id: 42,
        symbol: 'ETH',
        defaultCurrency: "ETH",
        explorer: 'https://kovan.etherscan.io',
        explorerApi: 'https://api-kovan.etherscan.io',
        provider: 'https://eth-kovan.alchemyapi.io/v2/9r8lwXZcACNfMovcyYaquN_CNK81Cqxi',
        modules: process.env.VUE_APP_KOVAN_MODULES.split(' '),
        nexusDistributor: '0x95454264489114534dD39D81E03Cf4003F948cA6',
        nexusAPI: 'https://api.staging.nexusmutual.io',
        brightContractRegistry: '0x269601a1825dB9194620477e2D251167Ae938117',
        insuraceCover: '',                                      //not used
        insuraceStakersPool: '',                                //not used
        insuraceAPI: '',                                        //not used
        insuraceAPIKey: '',                                     //not used
        insuraceReferral: '',
        ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        DAI: '0x5c422252c6a47cdacf667521566bf7bd5b0d769b',      // Nexus DAI
        USDT: '0xcc54b12a18f2c575ca97991046090f43c3070aa0',     //not used
        USDC: '',                                               //not used
        INSUR: '',                                               //not used
    },
    {
        name: 'BSC',
        id: 56,
        symbol: 'BSC',
        defaultCurrency: "BNB",
        explorer: 'https://bscscan.com',
        explorerApi: '',
        provider: 'https://bsc-dataseed.binance.org',
        modules: process.env.VUE_APP_BSC_MODULES.split(' '),
        bridgeRegistry: '',
        nexusDistributor: '',
        nexusAPI: '',
        brightContractRegistry: '',
        insuraceCover: '0xfBa24bdbb36001F1F88B3a552c77EC1c10f5E4C0',
        insuraceStakersPool: '0xd50E8Ce9D5c1f5228BCC77E318907bB4960578eF',
        insuraceAPI: 'https://api.insurace.io/ops/v1',
        insuraceAPIKey: 'H7C8k69Eiisz7AG1/6xcI5UWGluTtyAbizXrsfbfQIBDapQZEHAHFw==',
        insuraceReferral: '982107115070280393099561761653261738634756834311',
        ETH: '',
        BNB: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        'BUSD-T': '0x55d398326f99059ff775485246999027b3197955',
        INSUR:'0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30',
    },
    {
        name: 'BSC Testnet',
        id: 97,
        symbol: 'BSC',
        defaultCurrency: "BNB",
        explorer: 'https://testnet.bscscan.com',
        explorerApi: '',
        provider: 'https://data-seed-prebsc-1-s1.binance.org:8545',
        modules: process.env.VUE_APP_BSC_TESTNET_MODULES.split(' '),
        nexusDistributor: '',
        nexusAPI: '',
        brightContractRegistry: '',
        insuraceCover: '0x5f463Cc70f9706E63d4b927E25c28d5A709828b9',
        insuraceStakersPool: '',
        insuraceAPI: 'https://insurace-sl-microservice.azurewebsites.net',
        insuraceAPIKey: 'OmgwnM39a/M9/9Q856wbRkILcYh2ZmlJPpG9cVxT5Vy6aR8eNl3/jw==',
        insuraceReferral: '',
        ETH: '',
        BNB: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        BUSD: '0xb4A9462116681C87dE2457e325971682c684b021',
        USDC: '0x1Fc429DfF3Fa42EDF2C61CB1105c0471fC2F8a8e',
        'BUSD-T': '0x8AE79aB449Ee5E836a6683DFdBE2499876504247',
        INSUR:'0x3e31e00980536D1EE24b016f318420bD797bcBC7',
    },
    {
        name: 'Polygon',
        id: 137,
        symbol: 'POLYGON',
        defaultCurrency: "MATIC",
        explorer: 'https://polygonscan.com',
        explorerApi: '',
        //provider: 'https://polygon-mainnet.g.alchemy.com/v2/VeWz_iAaRaHf6zqmDnNkFcls6FrXd3Fm',
        //provider: 'https://floral-still-forest.matic.quiknode.pro/e65e7caf71baf127ce3a3408dc7843bac0c447d7/',
        // provider: 'https://polygon-rpc.com',
        provider: 'https://polygon.gateway.tenderly.co/5dAMHvCcwfL7tRtvt6T9BN',
        modules: process.env.VUE_APP_POLYGON_MODULES.split(' '),
        bridgeRegistry: '',
        nexusDistributor: '',
        nexusAPI: '',
        brightContractRegistry: '',
        insuraceCover: '0x3e00FE643337A0f4E345539096cA01e6B8d4374A',
        insuraceStakersPool: '0xD2171aBb60D2994CF9aCB767F2116Cf47BBF596F',
        insuraceAPI: 'https://api.insurace.io/ops/v1',
        insuraceAPIKey: 'H7C8k69Eiisz7AG1/6xcI5UWGluTtyAbizXrsfbfQIBDapQZEHAHFw==',
        insuraceReferral: '982107115070280393099561761653261738634756834311',
        ETH: '',
        MATIC: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        DAI: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
        USDT: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        USDC: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        INSUR: '0x8a0e8b4b0903929f47C3ea30973940D4a9702067',
    },
    {
        name: 'Mumbai Testnet',
        id: 80001,
        symbol: 'POLYGON',
        defaultCurrency: "MATIC",
        explorer: 'https://explorer-mumbai.maticvigil.com',
        explorerApi: '',
        provider: 'https://rpc-mumbai.matic.today',
        modules: process.env.VUE_APP_MUMBAI_TESTNET_MODULES.split(' '),
        nexusDistributor: '',
        nexusAPI: '',
        brightContractRegistry: '',
        insuraceCover: '0xE2Edf233eDB3F971415FD76A7b447e4bfFfcd221',
        insuraceStakersPool: '',
        insuraceAPI: 'https://insurace-sl-microservice.azurewebsites.net',
        insuraceAPIKey: 'OmgwnM39a/M9/9Q856wbRkILcYh2ZmlJPpG9cVxT5Vy6aR8eNl3/jw==',
        insuraceReferral: '',
        ETH: '',
        MATIC: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        DAI: '0xbF7f0C2B8E1C7631D8c99CB2CFF6523485e07CC7',
        USDT: '0x360974B871b280CdDcF076bDB6aB98624772E0b1',
        USDC: '0xb7c8bCA891143221a34DB60A26639785C4839040',
        INSUR: '0x0D3A05564821686CC7fB534eE44485B8025Eb4e8',
    },
    {
      name: 'Avalanche',
      id: 43114,
      symbol: 'AVALANCHE',
      defaultCurrency: "AVAX",
      explorer: 'https://snowtrace.io/',
      provider: 'https://api.avax.network/ext/bc/C/rpc',
      modules: process.env.VUE_APP_AVALANCHE_MODULES.split(' '),
      nexusDistributor: '',
      nexusAPI: '',
      brightProtocol:'',
      brightContractRegistry: '',
      insuraceCover: '0xfBa24bdbb36001F1F88B3a552c77EC1c10f5E4C0',
      insuraceAPI: 'https://api.insurace.io/ops/v1',
      insuraceAPIKey: 'H7C8k69Eiisz7AG1/6xcI5UWGluTtyAbizXrsfbfQIBDapQZEHAHFw==',
      insuraceReferral: '982107115070280393099561761653261738634756834311',
      ETH: '',
      MATIC: '',
      'DAIe': '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      'USDTe': '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      'USDCe': '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      INSUR: '0x544c42fBB96B39B21DF61cf322b5EDC285EE7429',
      AVAX: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  },
  {
      name: 'Avalanche Testnet',
      id: 43113,
      symbol: 'AVALANCHE',
      defaultCurrency: "AVAX",
      explorer: 'https://testnet.snowtrace.io/',
      provider: 'https://api.avax-test.network/ext/bc/C/rpc',
      modules: process.env.VUE_APP_AVALANCHE_TEST_MODULES.split(' '),
      nexusDistributor: '',
      nexusAPI: '',
      brightProtocol:'0x5C59A3A67eC553345cb384eC58FCf1a2b9159b15',
      brightContractRegistry: '',
      insuraceCover: '',
      insuraceAPI: 'https://insurace-sl-microservice.azurewebsites.net',
      insuraceAPIKey: 'OmgwnM39a/M9/9Q856wbRkILcYh2ZmlJPpG9cVxT5Vy6aR8eNl3/jw==',
      insuraceReferral: '',
      ETH: '',
      MATIC: '',
      'DAIe': '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      'USDTe': '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      'USDCe': '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      INSUR: '0xE2Edf233eDB3F971415FD76A7b447e4bfFfcd221',
      AVAX: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',

  },
    {
        name: 'Hardhat',
        id: 31337,
        symbol: 'ETH',
        defaultCurrency: "ETH",
        explorer: 'https://etherscan.io',
        explorerApi: 'https://api.etherscan.io',
        providerForHistory: 'https://eth.llamarpc.com/rpc/01GPB4WFEZFW0ATPTH5YXSAN91',
        provider: QUICKNODEPROVIDER,
        modules: ['NEXUS_MUTUAL'],
        bridgeRegistry: '0x8050c5a46FC224E3BCfa5D7B7cBacB1e4010118d',
        nexusDistributorV1: '0x3756C3C9374f38e0d9aAcB637Fed1641504a5b28',
        nexusDistributor: '0x425b3a68f1fd5de26b4b9f4be8049e36406b187a',
        nexusGateWay:'0x089Ab1536D032F54DFbC194Ba47529a4351af1B5',
        nexusAPI: 'https://api.nexusmutual.io',
        brightContractRegistry: '0xAA66e85682429a29363556ef757c44AfC5000F00',
        insuraceCover: '0x88Ef6F235a4790292068646e79Ee563339c796a0',
        insuraceStakersPool: '0x136D841d4beCe3Fc0E4dEbb94356D8b6B4b93209',
        insuraceAPI: 'https://api.insurace.io/ops/v1',
        insuraceAPIKey: 'H7C8k69Eiisz7AG1/6xcI5UWGluTtyAbizXrsfbfQIBDapQZEHAHFw==',
        insuraceReferral: '982107115070280393099561761653261738634756834311',
        ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        WETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
        USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        WNXM: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
        INSUR:'0x544c42fbb96b39b21df61cf322b5edc285ee7429',
        BMI:'0x725C263e32c72dDC3A19bEa12C5a0479a81eE688',
    },
]

const MAIN_NETS = [1, 56, 137, 43114];
const TEST_NETS = [4, 42, 97, 80001 ]; //using Kovan here as Eth testnet

const sixDecimalCurrencies = {
  1:[ 'USDT' , 'USDC'], //ETH Main
  56:[],//BSC Main
  137:[ 'USDT' , 'USDC'],//POLYGON Main
  43114 : ['USDTe' , 'USDCe'],//AVALANCHE Main
  4:[ 'USDT' , 'USDC'],//ETH Test
  42:[ 'USDT' , 'USDC'],//ETH Test
  97:[],//BSC Test
  80001:['USDT' , 'USDC'],//POLYGON Test
}

function getQuickNodeProvider() {
  return QUICKNODEPROVIDER;
}

function createWeb3Provider(provider) {
  return new Web3(new Web3.providers.HttpProvider(provider));
}

function netById(id) {
    return NETWORK_CONFIG.filter(net => {
        return net.id === Number(id)
    })[0]
}

function mainNets() {
    return MAIN_NETS
}

function testNets() {
    return TEST_NETS
}

function networkCurrency(id) {
    const obj = netById(id);
    return Object.keys(obj)
        .find(key => obj[key].toString().toUpperCase() === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'.toUpperCase());
}

function isNetworkCurrencyBySymbol(asset) {
    return asset === 'ETH' || asset === 'BNB' || asset === 'MATIC' || asset === "AVAX";
}

function requiresAllowanceReset(networkId, symbol) {
    if (netById(networkId).symbol === 'ETH' && symbol === 'USDT') {
        return true;
    }
}

function sixDecimalsCurrency(networkId, symbol) {
  return sixDecimalCurrencies[networkId].includes(symbol);
}

function isSupportedNetwork (_netId) {
  return MAIN_NETS.includes(Number(_netId)) || TEST_NETS.includes(Number(_netId))
}

module.exports = {
    NETWORK_CONFIG,
    netById,
    mainNets,
    testNets,
    isNetworkCurrencyBySymbol,
    requiresAllowanceReset,
    sixDecimalsCurrency,
    networkCurrency,
    isSupportedNetwork,
    createWeb3Provider,
    getQuickNodeProvider,
}
