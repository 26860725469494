'use strict'
import Vue from 'vue'
import Vuex from 'vuex'

import { createWeb3Provider } from '@/store/network_config'

import(/* webpackChunkName: "contractABIs" */ '@/utils/getContract')

import {
  getContractsRegistryContract,
  getIERC20Contract,
  getNexusGatewayContract,
  getNexusDistributorContract,
  getNexusMasterContract,
  getNexusStakingContract,
  getUniPairContract,
  getLiquidityMiningStakingContract,
  getBrightStakingContract,
  getBrightRiskTokenContract,
} from '@/utils/getContract'

import UniswapV2Api from '@/services/UniswapV2Api'
import { netById } from '@/store/network_config'

import {moduleWeb3} from '@/store/modules/web3.js'
import {moduleCovers} from '@/store/modules/covers.js'
import {moduleLoyalty} from '@/store/modules/loyalty.js'
import {moduleGas} from '@/store/modules/gas.js'
import {moduleBRI} from '@/store/modules/bri.js'
import {moduleStakeBright} from '@/store/modules/stake_bright.js'
import State from "@/mixins/State";
import { getIndexAddress} from '@/store/bri_config'
import { fromWei, toWei } from '@/utils/filters.js';

Vue.use(Vuex)

function availableOnNetwork(networkId, module) {
  return netById(networkId).modules.find(mod => mod === module);
}

export default new Vuex.Store({
  modules: {
    web3: moduleWeb3,
    covers: moduleCovers,
    loyalty: moduleLoyalty,
    gas: moduleGas,
    bri: moduleBRI,
    stake: moduleStakeBright,
  },
  state: {
    altImage: require("../assets/img/icons/preloader.svg"),

    //Bright Union
    contractsRegistry: null,
    brightToken: null,
    uniPairToken: null,
    eth_bright: null, //eth in bright
    stakingDeploymentBlock: '12970000',   //block in the beginning of Aug 2021

    //Nexus Mutual
    nexusMasterContract: null,
    nexusDistributorContract: null,
    nexusDistributorContractV1: null,
    nexusStakingContract: null,
    nexusGatewayContract: null,
    nexusCoverables: [],
    nexusQuotationContract: null,

    //stakes
    activeNexusStakes: {},
    // activeBridgeStakes: [],

    // value of eth in dai
    eth_dai: null,

  },
  mutations: {
    registerContractsRegistry(state, payload) {
      state.contractsRegistry = () => payload;
    },
    registerBrightToken (state, payload) {
      state.brightToken = () => payload;
    },
    registerUniPairToken (state, payload) {
      state.uniPairToken = () => payload;
    },
    resetCoverProviders(state){
      //Nexus Mutual
      state.nexusMasterContract= null;
      state.nexusDistributorContract= null;
      state.nexusDistributorContractV1= null;
      state.nexusStakingContract= null;
      state.nexusGatewayContract= null;
      state.nexusCoverables= [];

      //Loyalty
      this.commit("resetLoyaltyEvents"); // in loyalty module
    },
    registerNexusMaster (state, payload) {
      state.nexusMasterContract = () => payload;
    },
    registerNexusDistributor (state, payload) {
      state.nexusDistributorContract = () => payload;
    },
    registerNexusDistributorV1 (state, payload) {
      state.nexusDistributorContractV1 = () => payload;
    },
    registerNexusStaking (state, payload) {
      state.nexusStakingContract = () => payload;
    },
    registerNexusGateway (state, payload) {
      state.nexusGatewayContract = () => payload;
    },
    registerNexusQuotation (state, payload) {
        state.nexusQuotationContract = () => payload;
    },
    activeNexusStakes (state, payload) {
      state.activeNexusStakes = payload
    },
    nexusStats (state, payload) {
      state.nexusStats = payload;
    },
    nexusCoverables (state, payload) {
      state.nexusCoverables = payload;
    },
    registerBridgeBMICoverStaking(state, payload) {
      state.bridgeBMICoverStaking = () => payload;
    },
    eth_dai(state, payload) {
      state.eth_dai = payload.eth_dai;
    },
    eth_bright(state, payload){
      state.eth_bright = payload.eth_bright;
    },
  },
  actions: {
    setAltImg({state}){
      event.target.src = state.altImage;
    },
    registerContracts ({commit, state, dispatch, rootState, getters}) {

      commit('resetCoverProviders');

      //combines promises to be resolved once all coverables and essential data is available
      let allCoverablesReadyPromises = [];

      let ethereum = [state.web3.web3Active, ...state.web3.web3Passive].find(net => {
        return net.symbol === 'ETH'
      });

      allCoverablesReadyPromises.push(dispatch('getETHDAIPrice', {web3: ethereum}));

      if(!rootState.bri.briBalance && !rootState.web3.web3Active.readOnly){

        rootState.bri.indexAddress = getIndexAddress(getters.ethNetId);

        getBrightRiskTokenContract(rootState.bri.indexAddress, getters.xWeb3Eth).then((instance) => {

          rootState.bri.indexInstance = instance;

          rootState.bri.indexInstance.methods.convertIndexToInvestment(toWei(1)).call().then(value => {
            rootState.bri.indexTokenValue = Number(fromWei(value)).toFixed(5);
            rootState.bri.indexInstance.methods.balanceOf(getters.myAddress).call().then(balance => {
              let briBalance = fromWei(balance);
              commit("setBriBalance" , briBalance );
            });
          });

        })
      }

      if (availableOnNetwork(ethereum.networkId, 'BRIGHT_TOKEN')) {
        //Bright Union
        getContractsRegistryContract(netById(ethereum.networkId).brightContractRegistry, ethereum.web3Instance).then(async (contractsRegistry) => {
          //token
          contractsRegistry.methods.getBrightContract().call().then(brightTokenAddress => {
            getIERC20Contract(brightTokenAddress, ethereum.web3Instance).then((brightTokenContract) => {
              commit('registerBrightToken', brightTokenContract);
              commit('registerContractsRegistry', contractsRegistry);
            })
            dispatch('getBrightEthPrice', {web3: ethereum, brightAddress: brightTokenAddress});
          })

          //LP token
          contractsRegistry.methods.getUniswapBrightToETHPairContract().call().then(uniPairToken => {
            getUniPairContract(uniPairToken, ethereum.web3Instance).then((uniPairTokenContract) => {
              commit('registerUniPairToken', uniPairTokenContract);
            });
          });

          //Loyalty Program
          const [stakingAddress,lpStakingAddress] = await Promise.all([
            contractsRegistry.methods.getBrightStakingContract().call(),
            contractsRegistry.methods.getLiquidityMiningStakingContract().call()
          ]).then((_dataAddresses) => {
            return _dataAddresses;
          })

          const web3 = ethereum.readOnly ? createWeb3Provider(netById(1).providerForHistory) : ethereum.web3Instance ;

          const [stakingInstance,lpStakingInstance] = await Promise.all([
            getBrightStakingContract(stakingAddress, web3 ),
            getLiquidityMiningStakingContract(lpStakingAddress, web3 )
          ]).then((_dataInstances) => {
            return _dataInstances;
          })

          const brightPromiseArray = [];
          brightPromiseArray.push(
            stakingInstance.getPastEvents('WithdrawnBright', {
              filter: {
                recipient: state.web3.web3Active.address
              },
              fromBlock: state.stakingDeploymentBlock,
              toBlock: 'latest'
            })
          );
          brightPromiseArray.push(
            stakingInstance.getPastEvents('StakedBright', {
              filter: {
                recipient: state.web3.web3Active.address
              },
              fromBlock: state.stakingDeploymentBlock,
              toBlock: 'latest'
            })
          );
          brightPromiseArray.push(
            //LP Withdrawal
            lpStakingInstance.getPastEvents('Withdrawn', {
              filter: {
                user: state.web3.web3Active.address
              },
              fromBlock: state.stakingDeploymentBlock,
              toBlock: 'latest'
            })
          );
          brightPromiseArray.push(
            //LP Staking
            lpStakingInstance.getPastEvents('Staked', {
              filter: {
                user: state.web3.web3Active.address
              },
              fromBlock: state.stakingDeploymentBlock,
              toBlock: 'latest'
            })
          );

          Promise.all(brightPromiseArray).then((_data) => {
            commit('registerBrightWithdrawalEvents', _data[0]);
            commit('registerBrightStakingEvents', _data[1]);
            commit('registerLPWithdrawalEvents',_data[2]);
            commit('registerLPStakingEvents', _data[3]);
            dispatch('getLoyaltyDetails');
          }, () => {
            dispatch('getLoyaltyDetails');
          });

        });

      }

      if (availableOnNetwork(ethereum.networkId, 'NEXUS_MUTUAL')) {
        //Nexus
        allCoverablesReadyPromises.push(getNexusDistributorContract(netById(ethereum.networkId).nexusDistributor, ethereum.web3Instance).then((distributor) => {
          commit('registerNexusDistributor', distributor);

          distributor.methods.master().call().then(masterAddress => {
            getNexusMasterContract(masterAddress, ethereum.web3Instance).then((masterContract) => {
              commit('registerNexusMaster', masterContract);

              //get PooledStaking
              masterContract.methods.getLatestAddress(ethereum.web3Instance.utils.asciiToHex('PS')).call().then((stakingAddress) => {
                getNexusStakingContract(stakingAddress, ethereum.web3Instance).then(stakingContract => {
                  commit('registerNexusStaking', stakingContract);
                });
              })
              //get 'Gateway' contract
              distributor.methods.gateway().call().then(gatewayAddress => {
                getNexusGatewayContract(gatewayAddress, ethereum.web3Instance).then(async (gatewayContract) => {
                  commit('registerNexusGateway', gatewayContract);
                })
              });
            })
          })
        }).catch((err) => {
          console.error(err);
        }));

        allCoverablesReadyPromises.push(getNexusDistributorContract(netById(ethereum.networkId).nexusDistributorV1, ethereum.web3Instance).then((distributor) => {
          commit('registerNexusDistributorV1', distributor);

        }).catch((err) => {
          console.error(err);
        }));
      }

    },

    getETHDAIPrice ({commit}, {web3}) {
      if (availableOnNetwork(web3.networkId, 'UNISWAP')) {
        try {
          return UniswapV2Api.priceTokenAtoETH(web3.networkId, netById(web3.networkId).DAI).then(price => {
            commit('eth_dai', {
              eth_dai: price
            })
        })} catch(e) {
          console.error(e)
        }
      } else {
        //fallback to testnet ratio
        commit('eth_dai', {
          eth_dai: '2000'
        })
      }

    },
    getBrightEthPrice({ commit }, {web3, brightAddress}){
      if (availableOnNetwork(web3.networkId, 'UNISWAP')) {
        try {
          UniswapV2Api.priceTokenAtoETH(web3.networkId, brightAddress).then(price => {
            commit('eth_bright', {
              eth_bright: price
            })
          })
        } catch (e) {
          console.error(e)
        }
      } else {
        //fallback to testnet ratio
        commit('eth_bright', {
          eth_bright: '15000'
        })
      }
    },


    // ================= STAKES =======================
    // TODO: put in sub module for playground? or directly in popup module

    collectNexusStakes ({commit, state}) {
      if (availableOnNetwork( state.web3.web3Active.networkId, 'NEXUS_MUTUAL')) {
        state.nexusStakingContract().methods.stakerReward(state.web3.web3Active.address).call().then(totalReward => {
          state.nexusStakingContract().methods.stakerMaxWithdrawable(state.web3.web3Active.address).call().then(withdrawable => {
            state.nexusStakingContract().methods.stakerContractsArray(state.web3.web3Active.address).call().then(async (protocols) => {
              const nexusStake = {reward: totalReward, withdrawable: withdrawable};
              let protocolStakes = [];
              for (let i = 0; i < protocols.length; i++) {
                const myStakeInProtocol = await state.nexusStakingContract().methods.stakerContractStake(state.web3.web3Active.address, protocols[i]).call();
                const totalStakeInProtocol = await state.nexusStakingContract().methods.contractStake(protocols[i]).call();
                protocolStakes.push({protocol: protocols[i], stake: myStakeInProtocol, totalStake: totalStakeInProtocol});
              }
              nexusStake.protocolStakes = protocolStakes;
              commit('activeNexusStakes', nexusStake);
            });
          });
        });
      }
    },

  },
  getters: {
    eth2usd: (state) => (eth) => {
      return State.methods.toBN(eth.toString().split('.')[0]).mul(State.methods.toBN(state.eth_dai.toString().split('.')[0])).toString();
    },
    usd2eth: (state) => (dai) => {
      return State.methods.toBN(dai.toString().split('.')[0]).div(State.methods.toBN(state.eth_dai.toString().split('.')[0])).toString();
    },
    bright2eth: (state) => (bright) => {
      return State.methods.toBN(bright.toString().split('.')[0]).div(State.methods.toBN(state.eth_bright.toString().split('.')[0])).toString();
    },
  }
})
