import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {createApolloClient, restartWebsockets} from 'vue-cli-plugin-apollo/graphql-client'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'
// Files URL root
//export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

//Vue.prototype.$filesRoot = filesRoot

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  //wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

const clientOptionsNexusmutual = {
  httpEndpoint: httpEndpoint+ 'nexusmutual/nexus-mutual',
}
const clientOptionsProtofire = { //top 5 covers provider
  httpEndpoint: httpEndpoint + 'protofire/nexus-mutual',
}
const clientOptionsBrightIndex = {
  // url will change on mainnet deployment, will remove 'studio'
  // httpEndpoint: 'https://api.studio.thegraph.com/query/16865/brightindex/v0.1.1'
  httpEndpoint:'https://api.thegraph.com/subgraphs/name/danielifg/brightriskindex'
}
const clientOptionsBscSales = {
  httpEndpoint: httpEndpoint + 'danielifg/brightprotocolbsc',
}
const clientOptionsPolygonSales = {
  httpEndpoint: httpEndpoint + 'danielifg/brightprotocolmatic',
}
const clientOptionsAvalancheSales = {
  httpEndpoint: httpEndpoint + 'danielifg/brightprotocolavalanche2',
}
const clientOptionsNexusSales = {
  httpEndpoint: 'https://api.studio.thegraph.com/query/6822/nexussaleseth2/v0.7.0',
}

const clientOptionsMainnetSales = {
  httpEndpoint: 'https://api.studio.thegraph.com/query/16865/bu-distributors-mainnet/0.0.4',
}

// Call this in the Vue app file
export function createNexusProvider (options = {}) {

  const createNexusmutual= createApolloClient({
    ...defaultOptions,
    ...clientOptionsNexusmutual,
    ...options
  });

  const createProtofire = createApolloClient({
    ...defaultOptions,
    ...clientOptionsProtofire,
    ...options
  });

  const createBrightIndex = createApolloClient({
    ...defaultOptions,
    ...clientOptionsBrightIndex,
    ...options
  });

  const createBscSales = createApolloClient({
    ...defaultOptions,
    ...clientOptionsBscSales,
    ...options
  });

  const createPolygonSales = createApolloClient({
    ...defaultOptions,
    ...clientOptionsPolygonSales,
    ...options
  });

  const createAvalancheSales = createApolloClient({
    ...defaultOptions,
    ...clientOptionsAvalancheSales,
    ...options
  });

  const createNexusSales = createApolloClient({
    ...defaultOptions,
    ...clientOptionsNexusSales,
    ...options
  });

  const createMainnetSales = createApolloClient({
    ...defaultOptions,
    ...clientOptionsMainnetSales,
    ...options
  });

  const nexus = createNexusmutual.apolloClient;
  const protofire = createProtofire.apolloClient;
  const brightIndex = createBrightIndex.apolloClient;
  const bscSales = createBscSales.apolloClient;
  const polygonSales = createPolygonSales.apolloClient;
  const avalancheSales = createAvalancheSales.apolloClient;
  const nexusSales = createNexusSales.apolloClient;
  const mainnetSales = createMainnetSales.apolloClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      nexus,
      protofire,
      brightIndex,
      bscSales,
      polygonSales,
      avalancheSales,
      nexusSales,
      mainnetSales
    },
    defaultClient: nexus,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
