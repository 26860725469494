
import GasStationApi from "@/services/GasStationApi";

const moduleGas = {
  state: () => ({
    gasPrices: {},
  }),
  mutations: {
    setGasPriceFor(state, payload){
      state.gasPrices[payload.symbol] = payload.prices;
    }
  },
  actions: {
    fetchGasPrice({ commit , dispatch, state, rootState }, _symbol){
      GasStationApi.fetchGasPrice(_symbol).then((_data) => {
        commit("setGasPriceFor" , { symbol: _symbol, prices:_data} );

        let nets = [rootState.web3.web3Active, ...rootState.web3.web3Passive]
        for (var i = 0; i < nets.length; i++) {
          if(!state.gasPrices[nets[i].symbol]){
            dispatch("fetchGasPrice" , nets[i].symbol);
          }
        }

      });
    },
  }, // actions
  getters: {
    gasPriceForNet: (  state ) => (_symbol) => {
      if(_symbol && state.gasPrices[_symbol]){
        return state.gasPrices[_symbol];
      }else {
        return {standard: null, fast: null, rapid: null, slow: null};
      }
    },
  }

};

export {moduleGas}
