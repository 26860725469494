import {Fetcher, Route, Token, WETH} from '@uniswap/sdk'
import {ethers} from 'ethers'
import {getQuickNodeProvider} from '@/store/network_config'

export default class UniswapV2Api {

    static priceTokenAtoETH (chainId, tokenA) {
        const TokenA = new Token(Number(chainId), tokenA, 18);
        const provider = new ethers.providers.JsonRpcProvider( getQuickNodeProvider() );

        return Fetcher.fetchPairData(TokenA, WETH[chainId], provider).then(pair => {
            const route = new Route([pair], WETH[chainId])
            return route.midPrice.toSignificant(6);
        })
    }

    static priceTokenAtoTokenB (chainId, tokenA, tokenB) {
        const TokenA = new Token(Number(chainId), tokenA, 18);
        const TokenB = new Token(Number(chainId), tokenB, 18);

        const provider = new ethers.providers.JsonRpcProvider( getQuickNodeProvider() );

        return Fetcher.fetchPairData(TokenA, TokenB, provider).then(pair => {
            const route = new Route([pair], TokenB)
            return route.midPrice.toSignificant(6);
        })
    }
}
