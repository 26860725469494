import {fromWei} from "web3-utils";
import State from "@/mixins/State";


export default class LoyaltyService {

    static fetchLoyaltyDetails (web3, lpStakedEvents, stakedEvents, lpWithdrawnEvents, withdrawnEvents, uniswapLMContract) {
        return web3.web3Instance.eth.getBlockNumber().then(async (currentBlock) => {
            return web3.web3Instance.eth.getBlock(currentBlock).then(blockInfo => {
                return uniswapLMContract.methods.getReserves().call().then(reserves => {
                    return uniswapLMContract.methods.totalSupply().call().then(async supply => {
                        //BRIGHT amount of reserves
                        const brightAmount = Number(fromWei(reserves.reserve0));
                        const lpAmount = Number(fromWei(supply));
                        const lpToBrightRatio = Math.round(brightAmount / lpAmount);
                        //merge, sort, and convert staking events
                        const stakingEvents = await Promise.all(lpStakedEvents.concat(stakedEvents)
                            .sort((ev1, ev2) => ev1.blockNumber - ev2.blockNumber)
                            .map(async (event) => {
                                await web3.web3Instance.eth.getBlock(event.blockNumber).then((res) => {
                                    event.timestamp = res.timestamp
                                    return event
                                })
                                if (event.returnValues.amount) {//lp event
                                    event.returnValues.stakedBright = State.methods.toBN(event.returnValues.amount).mul(State.methods.toBN(lpToBrightRatio)).toString();
                                }
                                event.delta = event.returnValues.stakedBright;
                                return event;
                            }))
                        //merge, sort, and convert withdrawal events
                        const withdrawalEvents = lpWithdrawnEvents.concat(withdrawnEvents)
                            .sort((ev1, ev2) => ev1.blockNumber - ev2.blockNumber)
                            .map(event => {
                                if (event.returnValues.amount) {//lp event
                                    event.returnValues.withdrawnBright = State.methods.toBN(event.returnValues.amount).mul(State.methods.toBN(lpToBrightRatio)).toString();
                                }
                                event.delta = '-' + event.returnValues.withdrawnBright;
                                return event;
                            })
                        const achievements = [
                            {
                                level: 'no-status',
                                owns: true,
                                candidateSince: 0,
                                cashback: '',
                                min: 0,
                                max: 1000
                            },
                            {
                                level: 'gravel',
                                owns: false,
                                candidateSince: 0,
                                cashback: 5,
                                min: 1001,
                                max: 5000
                            },
                            {
                                level: 'pebble',
                                owns: false,
                                candidateSince: 0,
                                cashback: 10,
                                min: 5001,
                                max: 20000
                            },
                            {
                                level: 'cobble',
                                owns: false,
                                candidateSince: 0,
                                cashback: 15,
                                min: 20001,
                                max: 50000
                            },
                            {
                                level: 'boulder',
                                owns: false,
                                candidateSince: 0,
                                cashback: 20,
                                min: 50001,
                                max: false  //Irrelevant
                            }
                        ];
                        let holdings = State.methods.toBN(0);
                        stakingEvents.concat(withdrawalEvents).forEach(event => {
                            holdings = holdings.add(State.methods.toBN(event.delta));
                            let previous;
                            for (const achievement of achievements) {
                                LoyaltyService.testAchievement(achievement, previous, holdings, event.timestamp);
                                previous = achievement;
                            }
                            //console.log(fromWei(holdings));
                        })
                        //another round of checks for 'now'
                        let previous;
                        for (const achievement of achievements) {
                            LoyaltyService.testAchievement(achievement, previous, holdings, blockInfo.timestamp);
                            previous = achievement;
                        }
                        //console.log(fromWei(holdings));
                        const highestAchievement = achievements.reverse().find(achievement => achievement.owns);
                        highestAchievement.balance = Number(fromWei(holdings.toString()));
                        return highestAchievement;
                    })
                })
            })
        })
    }

    static testAchievement(achievement, previous, holding, timestamp) {
        let myCurrentDate = new Date();
        let timestamp90DaysAgo = new Date(myCurrentDate);
        timestamp90DaysAgo.setDate(timestamp90DaysAgo.getDate() - 90);
        switch (achievement.level) {
            case 'no-status':               //simple
                achievement.owns = true;
                break;
            case 'gravel':
            case 'pebble':
                if (achievement.owns && Number(fromWei(holding)) < achievement.min) {           //RESET STATUS
                    achievement.owns = false;
                } else if (!achievement.owns && Number(fromWei(holding)) > achievement.min) {   //PROMOTE TO ACHIEVEMENT
                    achievement.owns = true;
                }
                break;
            case 'cobble':
            case 'boulder':
                if (achievement.owns && Number(fromWei(holding)) < achievement.min) {           //RESET STATUS
                    achievement.owns = false;
                    achievement.candidateSince = 0;
                } else if (!achievement.owns && achievement.candidateSince == 0                 //PROMOTE TO CANDIDATE
                    && Number(fromWei(holding)) > achievement.min) {
                    achievement.candidateSince = timestamp;
                } else if (!achievement.owns && achievement.candidateSince > 0 &&               //PROMOTE TO ACHIEVEMENT
                    Number(fromWei(holding)) > achievement.min) {

                    const difference = Math.floor((+timestamp90DaysAgo - (achievement.candidateSince * 1000)) / 1000 / 60 / 60 / 24)
                    if (difference > 0) {
                        achievement.owns = true;
                    } else if (previous) {
                        let daysDifference = Math.floor(((achievement.candidateSince * 1000) - +timestamp90DaysAgo) / 1000 / 60 / 60 / 24)
                        previous.secLeftToPromotion = daysDifference;
                    }
                }
                break;
        }
    }

}
