import {netById} from '@/store/network_config'
// import {toBN , fromWei} from 'web3-utils'
import { fromWei, toBN, toWei } from '@/utils/filters.js';


export default {
    computed: {
        activeNetworkSymbol () {
            return this.$store.getters.activeNetworkSymbol;
        },
        activeNetworkId () {
            return this.$store.getters.activeNetworkId;
        },
        ethNetId () {
          return this.$store.getters.ethNetId
        },
    },
    methods: {
        xWeb3 () {
          return this.$store.getters.xWeb3;
        },
        xWeb3Eth (){
          return this.$store.getters.xWeb3Eth;
        },
        activeNetworkConfig () {
            return netById(this.$store.state.web3.web3Active.networkId);
        },
        toBN (val) {
          return toBN(val);
        },
        fromWei (value , type) {
          return fromWei(value, type);
        },
        fromWeiTwoDecimals (value) {
            return parseFloat(this.$store.state.web3.web3Active.web3Instance.utils.fromWei(value.toString(), 'ether')).toFixed(2);
        },
        toWei (value) {
            return toWei(value.toString());
        },
        myAddress () {
            return this.$store.getters.myAddress;
        },
        coverableByAddress (address , coverablesArray) {
          return this.$store.getters.coverableByAddress(address , coverablesArray);
        },
        coverableByName (name , coverablesArray) {
          return this.$store.getters.coverableByName(name , coverablesArray);
        },
        coverableByNexusProductId (productId , coverablesArray) {
            return this.$store.getters.coverableByNexusProductId(productId , coverablesArray);
        },
        getCoverable(_cover , _riskProtocol ){
          return this.$store.getters.getCoverable(_cover , _riskProtocol);
        },
        assetByAddress( address, netId ) {
          const netData = netById(netId);
          let assetName = 'N/A';
          for (const key in netData ) {
            if( typeof netData[key] == 'string' &&  netData[key].toUpperCase() == address.toUpperCase() ){
              assetName = key
              break;
            }
          }
          return assetName;
        },

        // @dev returns Promise
        currentBlock () {
            return this.$store.state.web3.web3Active.web3Instance.eth.getBlockNumber();
        },

        // @dev returns Promise
        blockToDate (blockNo) {
            //searching future only
            const secPerBlock = 13.5;
            let now = new Date();
            blockNo = Number(blockNo);
            return this.$store.state.web3.web3Active.web3Instance.eth.getBlockNumber().then(current => {
                if (current > blockNo) {
                    //past
                    return new Date(now.getTime() - (current - blockNo) * secPerBlock * 1000);
                } else {
                    //future
                    return new Date(now.getTime() + (blockNo - current) * secPerBlock * 1000);
                }
            });
        }
    },
};
