
/**
 * Transaction explorer with Web3
 */
 export default class ExplorerApi {
    /**
     * Query contract event by event type and filters
     *
     * @param {*} filters web3 to filter contract events
     * @param {*} web3Active active web3 Instance
     * @param {*} indexInstance index contract instance
     * @param {*} eventType  type of event to query for
     * @returns Available event data and general tx data
     */
    static async web3FetchUserTxByIndexEvent(filters,web3Active,indexInstance,eventType, from){
        const briEvents = await this.fetchIndexTxsByEvent(filters,web3Active,indexInstance,eventType, from);
        const eventTx = new Array();
        return new Promise((resolve) =>{
            if(briEvents.length > 0){
                briEvents && briEvents.forEach( (briEvent) => {
                       // let block = await web3Active.eth.getBlock(briEvent.blockNumber);
                        eventTx.push({
                            eventType,
                            date: 0,//;moment.unix(block.timestamp).format( "DD-MM-YYYY HH:MM:SS" ),
                            blockNumber: briEvent.blockNumber,//block.timestamp,
                            briEvent,
                        });
                   })
                }
                resolve(eventTx)
        })
    }

    static async fetchIndexTxsByEvent(filterObject,web3Active,indexInstance,eventType, from){
        let filter = filterObject ? filterObject : null;
        return new Promise((resolve,reject) => {
               indexInstance.getPastEvents(eventType, {
                   filter: filter,
                   fromBlock: from.toString(),
                   toBlock: 'latest'
                }, err => err)
               .then(async  eventsTx => {resolve(eventsTx)})
               .catch(err => reject(err));
        });
    }

    static async fetchTx(web3Active,txHash){
        return new Promise((resolve,reject) => {
                web3Active.eth.getTransaction(txHash)
               .then(async  tx => resolve(tx))
               .catch(err => reject(err));
        });
    }

    /**
     * Temporary method to get Nexus sales Data from its Gateway Contract
     */
    static async fetchNexusSalesData(brightNexusContract,gateWayNexusInstance){
        return new Promise((resolve,reject) => {
            gateWayNexusInstance.getPastEvents("CoverBought", {
                   filter: {to:brightNexusContract},
                   fromBlock: 13212759,
                   toBlock: 'latest'
                }, err => err)
               .then( async eventsTx => resolve(eventsTx))
               .catch(err => reject(err));
        });
    }

}
