
import LoyaltyService from "@/services/LoyaltyService";

const loyaltyOptions = [
    {
        status: 'no-status',
        imgSrc: null,
        next: 'bright gravel',

    },
    {
        status: 'gravel',
        imgSrc: require('../../assets/img/icons/status_gravel.svg'),
        next: 'bright pebble'
    },
    {
        status: 'pebble',
        imgSrc: require('../../assets/img/icons/status_pebble.svg'),
        next: 'bright cobble'
    },
    {
        status: 'cobble',
        imgSrc: require('../../assets/img/icons/status_cobble.svg'),
        next: 'bright boulder'
    },
    {
        status: 'boulder',
        imgSrc: require('../../assets/img/icons/status_boulder.svg'),
        next: ''
    }
]


const moduleLoyalty = {
  // namespaced: true,
  state: () => ({
    // providerListeners: false,
    loyaltyStakedEvents: [],
    loyaltyWithdrawnEvents: [],
    loyaltyLPStakedEvents: [],
    loyaltyLPWithdrawnEvents: [],
    statusLoaded: false,
    loyaltyDetails: {}

  }),
  mutations: {

    registerLPStakingEvents(state, payload) {
      payload.forEach((event) => state.loyaltyLPStakedEvents.push(event));
    },

    registerLPWithdrawalEvents(state, payload) {
      payload.forEach((event) => state.loyaltyLPWithdrawnEvents.push(event));
    },

    registerBrightStakingEvents(state, payload) {
      payload.forEach((event) => state.loyaltyStakedEvents.push(event));
    },

    registerBrightWithdrawalEvents(state, payload) {
      payload.forEach((event) => state.loyaltyWithdrawnEvents.push(event));
    },

    resetLoyaltyEvents(state){
      state.loyaltyStakedEvents = []
      state.loyaltyWithdrawnEvents =  []
      state.loyaltyLPStakedEvents = []
      state.loyaltyLPWithdrawnEvents = []
    },

  },//mutations
  actions: {

    getLoyaltyDetails({state,rootState, getters}){
      LoyaltyService.fetchLoyaltyDetails(
        { web3Instance: getters.xWeb3Eth },
        state.loyaltyLPStakedEvents,
        state.loyaltyStakedEvents,
        state.loyaltyLPWithdrawnEvents,
        state.loyaltyWithdrawnEvents,
        rootState.uniPairToken()
      ).then((_data) => {
        state.loyaltyDetails = _data;
        const statusAdditions = loyaltyOptions.filter(stakingStatus => stakingStatus.status === state.loyaltyDetails.level)[0];
        state.loyaltyDetails.imgSrc = statusAdditions.imgSrc
        state.loyaltyDetails.next = statusAdditions.next
        state.statusLoaded = true;
      })
    }

  }, // actions
  getters: {
    loyaltyOptions(){
      return loyaltyOptions;
    }
  }

};

export {moduleLoyalty}
