import {mainNets, netById, testNets, createWeb3Provider} from '@/store/network_config'
import EventBus from "@/utils/event-bus";
import Web3 from "web3";

import Onboard from '@web3-onboard/core'
const infuraId = '98d7e501879243c5877bac07a57cde7e';   //wallet connect
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseWalletModule from '@web3-onboard/coinbase'
import trezorModule from '@web3-onboard/trezor'
// import ledgerModule from '@web3-onboard/ledger'
import gnosisModule from '@web3-onboard/gnosis'

import { IFrameEthereumProvider } from '@ledgerhq/iframe-provider';
import { ethers } from 'ethers'

import LedgerLiveApi from "@ledgerhq/live-app-sdk";
import { WindowMessageTransport } from "@ledgerhq/live-app-sdk";

// import { Web3Provider } from 'ethers';
// const provider = new ethers.providers.JsonRpcProvider( getQuickNodeProvider() );


const onboard = Onboard({
  wallets: [
    injectedModule(),
    walletConnectModule({
      // bridge: 'YOUR_CUSTOM_BRIDGE_SERVER',
      rpc:{
        1: netById(1).provider,
        3: netById(3).provider,
        4: netById(4).provider,
      },
      qrcodeModalOptions: {
        mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar']
      }
    }),
    coinbaseWalletModule({ darkMode: true }),
    // ledgerModule(),
    trezorModule({
      email: 'info@brightunion.io',
      appUrl: 'https://app.brightunion.io'
    }),
    gnosisModule(),
    // portisModule(),
   ],
  chains: [
    {
      id: '0x1',  // chain ID must be in hexadecimel
      token: netById(1).symbol,
      label: netById(1).name,
      rpcUrl: netById(1).provider,
    },
    {
      id: '0x38',
      token: netById(56).symbol,
      label: netById(56).name,
      rpcUrl: netById(56).provider,
    },
    {
      id: '0x89',
      token: netById(137).symbol,
      label: netById(137).name,
      rpcUrl: netById(137).provider,
    },
    {
      id: '0xA86A',
      token: netById(43114).symbol,
      label: netById(43114).name,
      rpcUrl: netById(43114).provider,
    },
    {
      id: '0x7A69', //31337
      token: 'ETH',
      label: 'Hardhat',
      rpcUrl: 'http://127.0.0.1:8545',
    }
  ],
  appMetadata: {
    name: 'Bright Union',
    // icon: '',
    icon: '/img/b-icon.84835acf.png',
    // logo: '',
    logo: '/img/logo.58deca8e.svg',
    description: 'Get insurance for your assets.',
    // recommendedInjectedWallets: [
    //   { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
    //   { name: 'MetaMask', url: 'https://metamask.io' },
    //   { name: 'Wallet Connect', url: 'https://metamask.io' }
    // ]
  },
  notify: {
   enabled: false,
   transactionHandler: () => {
   }
 },
  accountCenter:{
    desktop:{
      enabled: false,
    },
    mobile:{
      enabled: false,
    },
  },
})

const moduleWeb3 = {
  // namespaced: true,
  state: () => ({
    infuraId: infuraId,   //wallet connect
    web3Active: {
      web3Instance: null,
      networkId: null,
      symbol: '',
      address: '',
      readOnly: true,
    },
    web3Passive: [],
    networkBalance: 0,
    providerNetworkId: null, // used for provider.on("chainChanged") listener
    providerAccount: null, // used for provider.on("accountChanged") listener
    providerListeners: false,
    embed: {
      active: false,
      source: null,
      provider: false,
    },
  }),
  mutations: {
    deRegisterWeb3Active(state) {
      localStorage.removeItem("bu_connected")
      state.web3Active.readOnly = true;
    },
    registerWeb3Passive (state, payload) {
      state.web3Passive = payload;
    },
    networkBalance (state, payload) {
      state.networkBalance = payload;
    },
    web3ActiveUpdate(state , payload){
      state.web3Active = payload;
    },
    embedActivate(state , payload){
      state.embed = payload;
      state.embed.active = true;
    },
    providerNetworkIdChanged(state , payload){ // used for provider.on("chainChanged") listener
      state.providerNetworkId = payload;
    },
    providerAccountUpdate(state , payload){ // used for provider.on("chainChanged") listener
      state.providerAccount = payload;
    }
  },//mutations
  actions: {
    registerWeb3Active ( { commit} , payload) {
      commit('web3ActiveUpdate' , payload);
    },
    async instantiateWeb3ReadOnly ({commit, dispatch} , disconnected ) {
      const fallbackNet = process.env.VUE_APP_FALLBACK_NET;
      const web3 = createWeb3Provider(netById(fallbackNet).provider);
      const web3Obj = {
        address: '0x0000000000000000000000000000000000000001',
        networkId: fallbackNet,
        symbol: netById(fallbackNet).symbol,
        web3Instance: web3,
        readOnly: true,
      };
      let passiveNets = [];
      //all but current
      if (mainNets().includes(Number(fallbackNet))) {
        passiveNets = mainNets().filter(net => net != Number(fallbackNet))
      } else {
        passiveNets = testNets().filter(net => net != Number(fallbackNet))
      }
      let web3Passive = [];
      for (let net of passiveNets) {
        web3Passive.push({
          address: '0x0000000000000000000000000000000000000001',
          networkId: net,
          symbol: netById(net).symbol,
          web3Instance: createWeb3Provider(netById(net).provider),
          readOnly: true,
        });
      }
      commit('registerWeb3Passive', web3Passive);
      dispatch('registerWeb3Active', web3Obj);
      if(!disconnected){
        dispatch("initBrightClient" , web3Obj.web3Instance).then( () => {
          dispatch('registerContracts');
        });
      }

      return web3Obj
    },
    updateNetworkBalance ({commit, state}) {
      if(!state.web3Active.web3Instance) return;
      state.web3Active.web3Instance.eth.getBalance(state.web3Active.address).then(eth => {
        commit('networkBalance', eth)
      });
    },
    async switchNetwork( {dispatch, state},{ networkId } ){

      let netIdNum = Number(networkId);
      let netIdHex = '0x' + netIdNum.toString(16);

      const newNet = netById(netIdHex);

      EventBus.publish('SHOW_NETWORK_SWITCH_MODAL', {show:true, newNet:newNet.name} );

      onboard.setChain({ chainId: netIdHex }).then(result => {
        if (result) {
          dispatch('updateWalletConnection' , {web3: state.web3Active.web3Instance });
        } else {
          EventBus.publish('SHOW_NETWORK_SWITCH_MODAL', {show:false} );
        }
      })

    },
    createWeb3Passive( { commit } ,{ address, nets}) {
      let web3Passive = [];
      for (let net of nets) {
        web3Passive.push({
          address: address,
          networkId: net,
          symbol: netById(net).symbol,
          web3Instance: createWeb3Provider(netById(net).provider),
          readOnly: true,
        });
      }
      commit('registerWeb3Passive', web3Passive);
    },
    async connectLedgerLive({ commit, dispatch,state }, {embedSource}){

      const ethereum = new IFrameEthereumProvider();
      const web3 = new ethers.providers.Web3Provider( ethereum );

      console.log("BU web3 --> " , web3);

      const llapi = new LedgerLiveApi(new WindowMessageTransport());

      llapi.connect();

      const accounts = await llapi.listAccounts();
      const ethAccounts = accounts.filter(a => a.currency == "ethereum" );
      const address = ethAccounts[0].address;
      const networkId = 1;

      console.log("BU AllAccounts --> " , accounts);
      console.log("BU ethAccounts --> " , ethAccounts);
      console.log("BU ethAccount --> " , address);

      commit('embedActivate', {
        source: embedSource,
        provider: web3,
        allAddress: ethAccounts,
        address: address,
      } );

      dispatch('registerWeb3Active', {
        address: address,
        networkId: networkId,
        symbol:"ETH",
        web3Instance: web3,
        readOnly:  false ,
      });

      dispatch("initBrightClient" , web3).then( () => {
        dispatch('registerContracts');
      });
      dispatch("createWeb3Passive" , { address: address , nets: mainNets().filter(net => net != Number(networkId)) });

      ethereum.on('accountsChanged', (_data) => {
        console.log("BU accountsChanged --> " , _data);
        state.embed.change = _data;
      });

      // let netTest = await ethereum.send('net_version');
      // console.log("BU netTest -- > " , netTest);

    },
    async initOnboarding({dispatch} , { appFirstLoad }){

      const cachedWallets = JSON.parse(
        window.localStorage.getItem('connectedWallets')
      )

      let wallets = null;
      if (cachedWallets && cachedWallets.length > 0 ) {
        wallets = await onboard.connectWallet({ autoSelect: cachedWallets[0]  })// disableModals: false
      }else if(!appFirstLoad){
        wallets = await onboard.connectWallet();
      }
      if(!wallets || wallets.length == 0) {
        if(appFirstLoad){
          dispatch("instantiateWeb3ReadOnly");
        }
        return {status:"readMode"};
      }

      const walletsListener = onboard.state.select('wallets')
      // const { unsubscribeWallets } =
      walletsListener.subscribe(walletsUpdate => {
        if(walletsUpdate.length == 0) {
          dispatch("instantiateWeb3ReadOnly" , true );
        }else {
          const web3 = new Web3(walletsUpdate[0].provider);
          dispatch('updateWalletConnection' , {web3: web3 });
        }
        const connectedWallets = walletsUpdate.map(({ label }) => label)
        window.localStorage.setItem(
          'connectedWallets',
          JSON.stringify(connectedWallets)
        )
      })

      const web3 = new Web3(wallets[0].provider);
      dispatch('updateWalletConnection' , {web3: web3 });
      return {status:"connected"};
    },
    async updateWalletConnection({dispatch} , {web3}){

      console.log("updateWalletConnection web3 - " , web3 );

      const networkId = await web3.eth.net.getId();
      const address = (await web3.eth.getAccounts())[0];

      const activeNetConfig = netById(networkId);
      if(activeNetConfig){

        localStorage.setItem("bu_connected", "true")

        dispatch('registerWeb3Active', {
          address: address,
          networkId: networkId,
          symbol: activeNetConfig.symbol,
          web3Instance: web3,
          // provider: wallets[0].provider,
          readOnly:  false ,
        });

        dispatch("initBrightClient" , web3).then( () => {
          dispatch('registerContracts');
        });

      }else {
         dispatch('instantiateWeb3ReadOnly');
      }

      if (mainNets().includes(Number(networkId))) {
        //all mainnets except current
        dispatch("createWeb3Passive" , {address: address , nets: mainNets().filter(net => net != Number(networkId)) })
      } else {
        //all testnets except current
        dispatch("createWeb3Passive" , {address: address , nets: testNets().filter(net => net != Number(networkId)) })
      }
    },

  }, // actions
  getters: {
    embed: (state) => {
      return state.embed;
    },
    myAddress: (state) => {
      return state.web3Active.address;
    },
    activeNetworkSymbol: (state) => {
        return state.web3Active.symbol;
    },
    activeNetworkId: (state) => {
        return state.web3Active.networkId;
    },
    xWeb3: (state) => {
      return state.web3Active.web3Instance;
    },
    xWeb3Eth: (state) => {
      const ethNet =  [state.web3Active, ...state.web3Passive].find(net => {
        return net.symbol === 'ETH'
      });
      return ethNet ? ethNet.web3Instance : false;
    },
    ethNetId: (state) => {
      const ethNet =  [state.web3Active, ...state.web3Passive].find(net => {
        return net.symbol === 'ETH'
      });
      return ethNet ? ethNet.networkId : false;
    },
  }

};

export {moduleWeb3}
