import Vue from 'vue'
import App from './App.vue'
import router from './router'
/*import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";*/
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCheckCircle, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {createNexusProvider} from './vue-apollo'
import VueClipboard from 'vue-clipboard2'
import VueGtag from "vue-gtag";
import vSelect from 'vue-select'
import Hotjar from 'vue-hotjar'
import "regenerator-runtime/runtime";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';
import moment from 'moment'

import {flexDecimals, usd} from './utils/filters.js';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueClipboard)
Vue.component('v-select', vSelect)
Vue.use(VueGtag, {
  config: { id: "G-KCNQQRKDP7" },
  appName: 'BrightUnionApp',
  enabled: process.env.NODE_ENV == 'production',
  pageTrackerEnabled: process.env.NODE_ENV == 'production',
  pageTrackerScreenviewEnabled: process.env.NODE_ENV == 'production'
}, router);

Vue.use (Hotjar, {
  id: '2667749', // Hotjar Site ID
  isProduction: process.env.NODE_ENV == 'production',
})

library.add([faCheckCircle, faChevronDown]);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.filter("usd", function(value) {
    return usd(value);
});

Vue.filter("flexDecimals", function( value ) {
  return flexDecimals(value);
});


Vue.config.productionTip = false
Vue.prototype.moment = moment;

//DISABLED Sentry for now

/*Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: window.location.host.includes("localhost") ? null :  "https://d1c95652b5ab4313b7484366e886bcec@o1110132.ingest.sentry.io/6138818",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost:3000" ,
        "test.brightunion.io" ,
        "app.brightunion.io", /^\//
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production 0.25 (recomended)
  tracesSampleRate: 0.8, // for now for low trafic, better to keep higher value
});*/


new Vue({
  router,
  store,
  apolloProvider: createNexusProvider({}),
  render: h => h(App)
}).$mount('#app')
